import { fetchJSON } from '@bonnet/next/fetch';

import { ClientAuth } from 'reaxl-auth';

export default function fetchSearchBookmarks(searchBookmarkId) {
    return ClientAuth.getHeaderAuthToken().then((res) => fetchJSON(
        '/account-api/searchbookmarks',
        {
            query: {
                searchBookmarkId,
            },
            circuitBreaker: {
                id: 'searchBookmark',
                timeout: 3000,
                resetTimeout: 30000,
            },
            credentials: Object.keys(res).length > 0 ? 'omit' : 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                ...res,
            },
        }
    ));
}

export async function fetchShareBookmark(shareBookmarkId) {
    return ClientAuth.getHeaderAuthToken().then((res) => fetchJSON(
        `/account-api/searchbookmarks/share-bookmark/${shareBookmarkId}`,
        {
            circuitBreaker: {
                id: 'shareBookmark',
                timeout: 3000,
                resetTimeout: 300000,
            },
            credentials: Object.keys(res).length > 0 ? 'omit' : 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                idtoken: 'idtoken',
                accesstoken: 'accesstoken',
                ...res,
            },
        }
    ));
}

export function updateSearchBookmark(bookmark) {
    return ClientAuth.getHeaderAuthToken().then((res) => fetchJSON(
        '/account-api/search',
        {
            body: bookmark,
            circuitBreaker: {
                id: 'updateSearchBookmark',
                timeout: 3000,
                resetTimeout: 300000,
            },
            method: 'POST',
            credentials: Object.keys(res).length > 0 ? 'omit' : 'same-origin',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                ...res,
            },
        }
    ));
}
