export default (() => {

    const _SESSIONSTORE = global.sessionStorage;
    const _PERSISTSTORE = global.localStorage;

    function constructor() {

        let useSessionStorage = false;
        let lastError = null;

        const _instance = {

            getError: () => lastError,

            useSession: (bool) => {
                useSessionStorage = bool;
            },

            serializePayload: (data) => JSON.stringify(data),

            deSerializePayload: (data) => JSON.parse(data),

            processAction: (data) => {

                /* setItem() may throw an exception if the storage is full
                   in addition, Safari sets quota to 0 bytes in private mode */

                try {
                    switch (data.action) {
                        case 'setItem':
                            data.store.setItem(data.key, _instance.serializePayload(data.value));
                            break;
                        case 'getItem':
                            return _instance.deSerializePayload(data.store.getItem(data.key));
                        case 'removeItem':
                            data.store.removeItem(data.key);
                            break;
                        default:
                    }
                    return true;
                } catch (e) {
                    lastError = e;
                    return false;
                }
            },

            sessionStore: (() => ({
                clear: (key) => _instance.clear(key, true),
                get: (key) => _instance.get(key, true),
                set: (key, value) => _instance.set(key, value, true),
            }))(),

            clear: (key, isSession) => _instance.processAction({
                action: 'removeItem',
                key,
                store: (isSession || useSessionStorage) ? _SESSIONSTORE : _PERSISTSTORE,
            }),

            get: (key, isSession) => _instance.processAction({
                action: 'getItem',
                key,
                store: (isSession || useSessionStorage) ? _SESSIONSTORE : _PERSISTSTORE,
            }),

            set: (key, value, isSession) => _instance.processAction({
                action: 'setItem',
                key,
                value,
                store: (isSession || useSessionStorage) ? _SESSIONSTORE : _PERSISTSTORE,
            }),
        };

        return _instance;
    }

    return {
        getInstance: () => constructor(),
    };

})();
