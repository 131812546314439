import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency } from 'atc-js';

import {
    ListColumns,
    SeeMore,
    Text,
} from 'reaxl';

import {
    getPricingModelList,
    getPricingTrimList,
} from '@/utilities/getPricingSEOData';
import getSrpPageTypes from '@/utilities/getSrpPageType';

import { kbbVRSDataDuck, queryDuck } from '@/ducks';

import {
    srpCrawlPathsDataMapsDuck,
    srpSEOPricingDuck,
} from '@/ducks/srp';

function FaqSEOContentContainer({ modelYearList, trimList }) {
    const dispatch = useDispatch();

    const queryList = useSelector(queryDuck.selectors.getDuckState);
    const pageType = getSrpPageTypes(queryList);
    const { isYMMUrl, isYMMTUrl } = pageType;
    const { makeCode, modelCode, startYear } = queryList;

    const loadSafetyInfo = useCallback(() => dispatch(srpSEOPricingDuck.creators.loadSafetyInfo()), [dispatch]);

    useEffect(() => {
        if (!!makeCode && !!modelCode) {
            loadSafetyInfo();
        }
    }, [loadSafetyInfo, makeCode, modelCode, startYear]);

    const { makesMap, modelsMap } = useSelector(srpCrawlPathsDataMapsDuck.selectors.getDuckState);
    const makeKnown = queryList?.makeCode && !Array.isArray(queryList?.makeCode);
    const modelKnown = queryList?.modelCode && !Array.isArray(queryList?.modelCode);
    const trimKnown = queryList?.trimCode && !Array.isArray(queryList?.trimCode);
    const trimName = trimKnown ? queryList?.trimCode?.split('|')?.[1] : '';

    const makeName = makeKnown && makesMap ? makesMap[queryList?.makeCode.toUpperCase()] : '';
    const modelName = modelKnown && modelsMap ? modelsMap[queryList?.modelCode] : '';

    const activeTrims = useSelector(kbbVRSDataDuck.selectors.getActiveModelTrims);
    const selectedTrim = useSelector(kbbVRSDataDuck.selectors.getSelectedMakeModelTrims);

    const seoSafetyInfo = useSelector(srpSEOPricingDuck.selectors.getSafetyInfo);

    const pricingModelList = getPricingModelList(modelYearList, makeName, modelName, trimName);
    const pricingTrimList = getPricingTrimList(trimList, makeName, modelName);
    const exactTrimPricing = pricingTrimList?.find((entry) => entry.trimCode === trimName);

    const mmLabel = `${makeName} ${modelName}`;
    const ymmLabel = `${startYear} ${mmLabel}`;
    const ymmtLabel = `${ymmLabel} ${trimName}`;

    const faqItems = [];
    let typeLabel;

    if (isYMMUrl) {
        typeLabel = ymmLabel;
        const mpgString = (trim) => `${trim?.trimName} ${trim?.bodyStyle}: ${trim?.epaCity} mpg in the city and ${trim?.epaHwy} mpg on the highway`;
        const evString = (trim) => `${trim?.trimName} ${trim?.bodyStyle}: ${trim?.epaMpgeCity} MPGe in the city and ${trim?.epaMpgeCity} MPGe on the highway`;
        const onlyOneYearOfData = !!activeTrims && activeTrims.filter((trim) => trim.year === activeTrims[0]?.year);
        const onlyAvailableMpgs = onlyOneYearOfData.filter((trim) => (!!trim?.epaCity && !!trim.epaHwy) || (!!trim?.epaMpgeCity && !!trim?.epaMpgeHwy));
        const mpgsList = onlyAvailableMpgs.map((trim) => (trim?.engineType !== 'Electric' ? mpgString(trim) : evString(trim)));

        faqItems.push({
            question: `What is the MPG of the ${ymmLabel}?`,
            answer: (
                <>
                    This number varies by trim:
                    <ListColumns
                        className="list-bulleted padding-top-3 padding-left-0 text-size-300"
                        items={mpgsList}
                    />
                </>),
        });
    }

    if (isYMMTUrl) {
        typeLabel = ymmtLabel;
        const mpgString = !!selectedTrim && `${selectedTrim[0]?.epaCity} mpg in the city and ${selectedTrim[0]?.epaHwy} mpg on the highway`;
        const evString = !!selectedTrim && `${selectedTrim[0]?.epaMpgeCity} mpge in the city and ${selectedTrim[0]?.epaMpgeHwy} mpge on the highway`;
        const mpgAnswer = selectedTrim[0]?.engineType !== 'Electric' ? mpgString : evString;

        faqItems.push({
            question: `What is the MPG of the ${typeLabel}?`,
            answer: mpgAnswer,
        });

        const safetyInfoSpecs = !!seoSafetyInfo && seoSafetyInfo[0]?.features.Specifications;
        const legRoom = !!safetyInfoSpecs && safetyInfoSpecs.filter(({ label }) => label.includes('Legroom'));

        const renderLegroomItems = !!legRoom && legRoom.map(({ label, value }) => {
            const location = label.split(' ');
            const addRow = location.includes('First' || 'Second' || 'Third');
            return (
                <span key={`${location}-value}`}>
                    {`${location[0]}`}
                    {addRow && ' row'}
                    {`: ${value} of legroom`}
                </span>
            );
        });

        faqItems.push({
            question: `What is the legroom of ${typeLabel}?`,
            answer: !!legRoom
            && (
                <ListColumns
                    className="list-bulleted padding-left-0 text-size-300"
                    items={renderLegroomItems}
                />
            ),
        });
    }

    const renderQuestions = () => (
        <>
            <div className="margin-bottom-5">
                <Text
                    weight="bold"
                    componentClass="h3"
                    size={400}
                >
                    How much should I pay for a used
                    {' '}
                    {`${typeLabel}`}
                    ?

                </Text>

                <Text
                    componentClass="div"
                >
                    Autotrader price range:
                </Text>

                <div className="padding-top-3">
                    {isYMMUrl && !!pricingModelList && `${typeLabel}: ${pricingModelList?.links[0]?.formattedMinPrice} - ${pricingModelList?.links[0]?.formattedMaxPrice}`}

                    {isYMMTUrl && !!exactTrimPricing && (
                        <>
                            {formatCurrency(exactTrimPricing?.startingPrice)}
                            {' - '}
                            {formatCurrency(exactTrimPricing?.maxPrice)}
                        </>
                    ) }
                </div>
            </div>

            {!!faqItems && faqItems.map(({ index, question, answer }) => (
                <div
                    className="margin-bottom-5"
                    key={index}
                >
                    {!!question && (
                        <Text
                            weight="bold"
                            componentClass="h3"
                            size={400}
                        >
                            {question}
                        </Text>
                    )}

                    {!!answer && (
                        <Text>
                            {answer}
                        </Text>
                    )}
                </div>
            ))}
        </>
    );

    return (
        <div className="padding-top-4">
            <SeeMore>
                {renderQuestions()}
            </SeeMore>
        </div>
    );
}

export default FaqSEOContentContainer;
