import React from 'react';

import { useFeatures } from 'reaxl-features';
import { MyWalletGridContainer as ConnectedMyWalletGrid } from 'reaxl-wallet';

import { paymentsDuck } from '@/ducks';

function MyWalletGridContainer(props) {
    const {
        srp_wallet_grid_promo_card: [enableWalletPromoCard],
    } = useFeatures(['srp_wallet_grid_promo_card']);

    return (
        !enableWalletPromoCard
        && (
            <div className="col-xs-12">
                <ConnectedMyWalletGrid
                    uiContext={props.uiContext || 'basic'}
                    paymentsDuck={paymentsDuck}
                />
            </div>
        )

    );
}

export default MyWalletGridContainer;
