import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'fixedAd',
    initialState: {
        adPos: 'top',
        closeFixedAd: false,
        showFixedAd: false,
        scrolled: false,
    },
}).extend({
    selectors: () => ({
        getCloseFixedAd: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).closeFixedAd),
        getShowFixedAd: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).showFixedAd),
        getScrolled: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).scrolled),
    }),
});
