import {
    buildModelSeriesFilterOption,
    buildPackagesFilterOption,
    buildTrimFilterOption,
} from '@/utilities/getReferenceFiltersData';

export default async (ctx, isFilterGroups) => {
    const lscData = ctx?.data?.filters;
    const lscFilterGroupsData = ctx?.data?.filterGroups;
    const queryList = ctx?.query;
    const modelListData = await buildModelSeriesFilterOption(lscData, 'modelCode', queryList, lscFilterGroupsData, isFilterGroups) || {};
    const trimList = await buildTrimFilterOption(lscData, 'trimCode', queryList, lscFilterGroupsData, isFilterGroups) || {};
    const packageList = await buildPackagesFilterOption(lscData, 'packages', queryList, lscFilterGroupsData, isFilterGroups) || {};

    const filterGroupObj = {
        ...(trimList != null && trimList?.trimCode) ? { trimCode: { ...trimList.trimCode } } : '',
        ...(modelListData != null && modelListData?.modelCode) ? { modelCode: { ...modelListData.modelCode } } : '',
        ...(modelListData != null && modelListData?.seriesCode) ? { seriesCode: { ...modelListData.seriesCode } } : '',
        ...(packageList != null && packageList) ? { ...packageList } : '',
    };
    return filterGroupObj || {};
};
