import { fetchJSON } from '@bonnet/next/fetch';

export default (search) => fetchJSON('/rest/lsc/listing', {
    circuitBreaker: {
        id: 'supplementalSearch',
        timeout: 5000,
        resetTimeout: 30000,
        fallback: {},
    },
    headers: {
        'X-fwd-svc': 'atc',
    },
    credentials: 'same-origin',
    query: { ...search },
});
