import { useSelector } from 'react-redux';

import { getConsumerMetadata, useAnalytics } from 'reaxl-analytics';

import { srpFiltersDuck } from '@/ducks/srp';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

export default function useSendSRPPageEvent() {

    const { sendPage } = useAnalytics();

    const peData = useSelector(PersonalizationEngineModule.duck.selectors.selectPersonalization);
    const pePreferences = useSelector(PersonalizationEngineModule.duck.selectors.selectPreferences);

    const filterValueLabels = useSelector(srpFiltersDuck.selectors.getFilterValueLabels);

    // TODO: BONNET NEXT - I don't see how this event argument is being populated...
    // original usePageEvent only passed an isInitialLoad
    return async ({
        isInitialLoad = false,
        event,
    } = {}) => {
        try {
            const consumerMetadata = await getConsumerMetadata();

            sendPage({
                data: {
                    peData,
                    pePreferences,
                    filterValueLabels,
                    consumerMetadata,
                },
                isInitialLoad,
                event,
            });

        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to send Page Event', error);
        }
    };
}
