import React, { useEffect } from 'react';

import { Button } from 'reaxl';

export default () => {

    useEffect(() => {
        const err = new Error('srp base call has returned fallback');

        if (window && window.newrelic) {
            window.newrelic.noticeError(err, {
                Client: true,
                Error: err + ' - ' + err.stack,
                location: window.location.href,
            });
        }
    }, []);

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
            }}
            className="display-flex justify-content-center align-items-center"
        >
            <div className="text-align-center">
                <div className="margin-bottom-2">We are sorry.  There was an issue with your last search.</div>
                <Button
                    block
                    bsStyle="primary"
                    onClick={() => document.location.reload()}
                >
                    Please try again

                </Button>
            </div>
        </div>
    );
};
