import React, { memo, useState } from 'react';

import { connect } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import {
    Button,
    Carousel,
    Collapsible,
    Glyphicon,
    PositionedOverlay,
    Text,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { InventoryImage } from 'reaxl-molecules';

import { imageSizes } from 'axl-config';

import compareListingsHandler from '@/utilities/compareListingsHandler';

import {
    compareListingsDuck,
    eLotResultsDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
} from '@/ducks/srp';
import {
    vdpActiveInteractionDuck,
} from '@/ducks/vdp';

// constants
const NUM_COMPARE = 5;
const IMG_HEIGHT = imageSizes.inventoryThumb.height;
const IMG_WIDTH = imageSizes.inventoryThumb.width;
const imgClasses = 'margin-right-4 margin-bottom-2';

/*
 * Collapsible carousel of compared listings in state
 */
function CompareListingsDrawer({
    actions: {
        fetchCompareListings = () => { },
        removeCompareListing = () => { },
        // removeAllListings = () => { },
        setActiveInteractionSrp = () => { },
        setActiveInteractionVdp = () => { },
        setElotLoading = () => { },
    },
    isCollapsible = false,
    isSrp,
    isVdp,
    parent,
    listings,
    listingIds,
    isStickyHeader = false,
}) {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const greaterThanSmall = _get(device, 'greaterThan.sm', false);
    const orientation = !isXs ? 'landscape' : 'portrait';
    const isParentVdpPaginate = parent === 'vdp-paginate';

    const {
        compare: [, {
            showSpotlight,
        }],
    } = useFeatures([
        'compare',
    ]);

    const drawerClassname = 'margin-vertical-2';

    const [showCarousel, setShowCarousel] = useState(false);

    const handleToggleCollapsible = () => {
        setShowCarousel(!showCarousel);
    };

    const handleClearAllListings = (e) => {
        e.preventDefault(); // prevent scrolling down if triggered by space
        // TODO debug why removeAllListings causes show/hide functionality to break
        listingIds.forEach((listingId) => {
            removeCompareListing(listingId);
        });
    };

    const numPlaceHolders = NUM_COMPARE - listingIds.length;

    let setActiveInteraction = () => { };
    if (isSrp) {
        setActiveInteraction = setActiveInteractionSrp;
    } else if (isVdp) {
        setActiveInteraction = setActiveInteractionVdp;
    }

    const getListingsToCompare = () => listings.filter((listing) => listing.id).map((listing) => {
        const primary = _get(listing, ['images', 'primary'], 0);
        const image = _get(listing, `images.sources[${primary}].src`, '');

        const renderImage = () => (
            <InventoryImage
                alt="compare-vehicle"
                src={image}
                lazyLoad={false}
                sizes={{
                    height: IMG_HEIGHT,
                    width: IMG_WIDTH,
                }}
                uiContext="card"
            />
        );

        const renderRemoveIcon = () => (
            <Button
                className="margin-right-1 margin-top-1 text-size-500 bg-blue-darker glyphicon-stack img-rounded"
                onClick={(e) => {
                    e.preventDefault(); // prevent scrolling down if triggered by space
                    removeCompareListing(listing?.id);
                }}
                bsStyle="text"
            >
                <Glyphicon
                    glyph="remove"
                />
            </Button>
        );

        return (
            <div
                className={imgClasses}
                key={`compare-${listing.id}`}
            >
                <PositionedOverlay
                    shrinkwrap={false}
                    style={{
                        height: IMG_HEIGHT,
                        width: IMG_WIDTH,
                    }}
                >
                    <PositionedOverlay.Base style={{ height: IMG_HEIGHT }}>
                        {renderImage()}
                    </PositionedOverlay.Base>
                    <PositionedOverlay.Anchor position="topRight">
                        {renderRemoveIcon()}
                    </PositionedOverlay.Anchor>
                </PositionedOverlay>
            </div>
        );
    });

    const getPlaceHolders = () => {
        const carouselItems = [];

        for (let i = 0; i < numPlaceHolders; i++) {
            const renderImage = () => (
                <div
                    className="bg-gray-dark"
                    style={{
                        height: IMG_HEIGHT,
                        width: IMG_WIDTH,
                    }}
                />
            );

            carouselItems.push((
                <div
                    className={imgClasses}
                    key={`placeholder-${i}`}
                >
                    {renderImage()}
                </div>
            ));
        }
        return carouselItems;
    };

    const renderAddMoreText = () => {
        const containerStyles = !isXs ? {
            height: IMG_HEIGHT,
        } : {};
        const drawerUserMessage = (numPlaceHolders > 0) ? `Add up to ${numPlaceHolders} more cars` : 'Limit reached.';

        return (
            <div
                className="col-xs-8 col-sm-2 col-md-3 col-lg-4 padding-horizontal-0 padding-horizontal-sm-2"
            >
                <div
                    className="display-sm-flex justify-content-sm-center align-items-sm-center padding-right-sm-2"
                    style={containerStyles}
                >
                    <Text
                        componentClass="div"
                        weight="bold"
                    >
                        {drawerUserMessage}
                    </Text>
                </div>
            </div>
        );
    };

    const renderCarousel = () => (
        <div
            className={clsx('col-xs-12 col-sm-7 col-md-6 col-lg-5 padding-horizontal-0', {
                'display-grid justify-content-center': greaterThanSmall && parent === 'sticky',
            })}
        >
            <Carousel
                initialIndex={0}
                fullHeight
                fullWidth={false}
                onStaticClick={() => { }}
                onSettle={() => { }}
                onNextButtonClick={() => { }}
                onPrevButtonClick={() => { }}
                lazyLoad={false}
                prevNextButtons={orientation === 'landscape' && listingIds.length}
                fullWidthScroll
                visibilityThreshold={0.9}
            >
                {getListingsToCompare()}
                {getPlaceHolders()}
            </Carousel>
        </div>
    );

    const handleClickCompare = (event) => {
        if (typeof setElotLoading === 'function') {
            setElotLoading();
        }

        compareListingsHandler({
            addToActiveResults: false,
            event,
            fetchCompareListings,
            listing: null,
            listingIds,
            setActiveInteraction,
            wasSticky: false,
            showSpotlight,
        });
    };

    const renderCompareCTAs = () => {
        const containerStyle = !isXs ? {
            height: IMG_HEIGHT,
        } : {};

        return (
            <div
                className="col-xs-12 col-sm-3 display-flex justify-content-center align-items-center margin-bottom-2"
                style={containerStyle}
            >
                {listingIds.length > 1 && (
                    <Button
                        className="margin-right-2"
                        data-cmp="compare-cta"
                        bsSize="small"
                        bsStyle="secondary"
                        onClick={handleClickCompare}
                    >
                        Compare
                    </Button>
                )}
                {listingIds.length > 0 && (
                    <Button
                        onClick={handleClearAllListings}
                        bsStyle="text"
                    >
                        Clear All
                    </Button>
                )}
            </div>
        );
    };

    const renderCompareCarousel = () => (
        <div className={drawerClassname}>
            {!isXs && renderAddMoreText()}
            {renderCarousel()}
            {renderCompareCTAs()}
        </div>
    );

    const renderToggle = () => {
        const toggleClasses = clsx('padding-bottom-2 text-right col-xs-4', {
            'col-sm-12': showCarousel,
        });
        return (
            <Button
                className={toggleClasses}
                onClick={handleToggleCollapsible}
                bsStyle="text"
            >
                <Text>
                    {showCarousel ? 'Hide' : 'Show'}
                </Text>
                <Glyphicon
                    bsClass="glyphicon"
                    className="text-size-200 margin-left-2"
                    glyph={showCarousel ? 'chevron-up' : 'chevron-down'}
                />
            </Button>
        );
    };

    const renderCollapsibleHeader = () => (
        <div className="margin-vertical-1">
            {renderAddMoreText()}
            {renderToggle()}
        </div>
    );

    const renderDrawer = () => ((isCollapsible && isXs) ? (
        <Collapsible
            toggleHeader={renderCollapsibleHeader()}
            className="margin-bottom-3 padding-horizontal-2"
            initialCollapse
        >
            {showCarousel ? renderCompareCarousel() : <div />}
        </Collapsible>
    ) : renderCompareCarousel());

    const renderStickyDrawer = () => (
        <div className="container padding-0">
            {renderDrawer()}
        </div>
    );

    const renderCompareDrawer = () => (!isStickyHeader ? renderDrawer() : renderStickyDrawer());

    const drawerClasses = clsx('inverted bg-gray-darker', {
        'margin-bottom-4': parent === 'top',
        'col-xs-12': !isParentVdpPaginate,
        'container-fluid': isParentVdpPaginate,
    });

    return (
        <div
            className={drawerClasses}
            data-cmp={`cntnr-compare-drawer-${parent}`}
            key={parent}
        >
            {renderCompareDrawer()}
        </div>
    );
}

const mapStateToProps = (state) => {
    const pageId = _get(state, 'birf.pageData.page.BIRF.pg', '') || _get(state, 'birf.pageData.page.BIRF.pg_id', '');
    const isSrp = pageId === 'fyc_srl' || pageId === 'fyc_ncf';
    const isVdp = pageId === 'fyc_vdp';

    const listings = compareListingsDuck.selectors.getActiveInventory(state) || [];
    const listingIds = compareListingsDuck.selectors.getActiveListingIds(state) || [];

    return {
        isSrp,
        isVdp,
        listings,
        listingIds,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    fetchCompareListings: compareListingsDuck.creators.loadListingsData,
    removeCompareListing: compareListingsDuck.creators.removeFromCompare,
    removeAllListings: compareListingsDuck.creators.clearAll,
    setActiveInteractionSrp: srpActiveInteractionDuck.creators.setKeys,
    setActiveInteractionVdp: vdpActiveInteractionDuck.creators.setKeys,
    setElotLoading: eLotResultsDuck.creators.setLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(
    CompareListingsDrawer,
    (oldProps, newProps) => _isEqual(newProps.listings, oldProps.listings)
        && _isEqual(newProps.listingIds, oldProps.listingIds)
        && _isEqual(newProps.isSrp, oldProps.isSrp)
        && _isEqual(newProps.isVdp, oldProps.isVdp)
        && _isEqual(newProps.parent, oldProps.parent)
));
