import keyValueTransformer from './keyValueTransformer';

const paramValues = {
    bodyStyleCodes: 'bodyStyleCodes',
    driveGroup: 'driveGroups',
    doorCodes: 'doorCode',
    zip: 'searchZip',
    engineCodes: 'engineGroups',
    extColorsSimple: 'extColorGroups',
    fuelTypeGroup: 'fuelTypeGroups',
    interiorColorsSimple: 'intColorGroups',
    listingTypes: 'listingTypes',
    makeCodeList: 'makeCodes',
    modelCodeList: 'modelCodes',
    seriesCodeList: 'classSeriesCodes',
    sortBy: 'sort',
    transmissionCodes: 'transmissionGroups',
    trimCodeList: 'trimCodes',
    vehicleStyleCodes: 'styleCodes',
};
const arrayConvList = [
    'mpgRanges',
    'dealTypes',
    'bodyStyleSubtypeCodes',
    'sellerTypes',
    'featureCodes',
    'sellerTypes',
    'bodyStyleCodes',
];
const replaceKey = ({ searchObj, oldKey, newKey }) => {
    const newSearch = {
        ...searchObj,
    };
    newSearch[newKey] = newSearch[oldKey];
    delete newSearch[oldKey];
    return newSearch;
};
export function transformSearch(search) {
    const newSearch = replaceKey({
        searchObj: search,
        oldKey: 'zip',
        newKey: 'searchZip',
    });
    return keyValueTransformer.transformSearch({
        search: newSearch,
        transformKeys: paramValues,
        arrayConversions: arrayConvList,
    });
}
export default {
    transformSearch,
};
