import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import hasDom from 'has-dom';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';

// atc

import { useDevice } from '@bonnet/next/device';

import { isMobile, waitUntil } from 'atc-js';

import { CloseButton } from 'reaxl';
import { useMessageEvent } from 'reaxl-ads';
import { useAnalytics } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import { useMutationObserver } from 'reaxl-hooks';

import { srpFixedAdDuck, srpResultsDuck } from '@/ducks/srp';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

// ad sizes
const adSize320x50 = [[320, 50], [300, 50]];

const positionDefaultSizes = [[728, 90]];

// ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
const positionDefaultSizesOnDesktop = [[728, 90], [970, 90]];

const fixedAdSizes = {
    top: { default: positionDefaultSizes, lg: positionDefaultSizesOnDesktop, xs: adSize320x50 },
};

const isPubadsReady = () => window && window.googletag && window.googletag.apiReady && window.googletag.pubadsReady && typeof window.googletag.pubads === 'function';

function FixedAdContainer({
    className,
}) {
    const {
        srpleaderboardtiming: [, {
            interval: intervalValue = 30000,
            refreshLimit: refreshLimitValue = 25,
        }],
        DISABLE_SRP_STICKY_AD: [disableAd],
        DISABLE_SRP_STICKY_AD_LISTENER: [disableAdListener],
        disable_ads: [adsDisabled],
        disable_fixed_ads: [isDisabledFixedAds],
    } = useFeatures(['DISABLE_SRP_STICKY_AD', 'DISABLE_SRP_STICKY_AD_LISTENER', 'disable_ads', 'srpleaderboardtiming', 'disable_fixed_ads']);

    const { sendClick } = useAnalytics();

    const cmpId = 'fixedAdContainer';
    let slotId = 'fixedAdMobile';

    const isComputer = !isMobile();
    const closeFixedAd = useSelector(srpFixedAdDuck.selectors.getCloseFixedAd);
    const showFixedAd = useSelector(srpFixedAdDuck.selectors.getShowFixedAd);
    const scrolled = useSelector(srpFixedAdDuck.selectors.getScrolled);
    const hasResults = useSelector(srpResultsDuck.selectors.hasActiveResults);
    const { brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const dispatch = useDispatch();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const overlayOpen = !disableAdListener ? useMutationObserver(cmpId, 'style') : false;

    const getScrollPercent = useMemo(() => _throttle(() => {
        const h = document.documentElement;
        const b = document.body;
        const st = 'scrollTop';
        const sh = 'scrollHeight';
        const currentScrollPercent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
        if (currentScrollPercent > 10 && !scrolled) {
            dispatch(srpFixedAdDuck.creators.setKeys({
                scrolled: true,
            }));
            window.removeEventListener('scroll', getScrollPercent);
        }
        // Disable exhaustive dependencies so that we only create one scroll listener on the window and not multiple.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 100, { leading: true }), []);

    useEffect(() => {
        window.addEventListener('scroll', getScrollPercent);

        return () => window.removeEventListener('scroll', getScrollPercent);
    }, [getScrollPercent]);

    /* find & refresh the fixed ad every 30 seconds up to 25 times */
    function useAutoRefreshTimer() {
        const [count, setCount] = useState(1);
        const optInterval = intervalValue && Number(intervalValue);
        const interval = (optInterval) || 30000;

        const optRefreshLimit = refreshLimitValue && Number(refreshLimitValue);
        const refreshLimit = (optRefreshLimit) || 25;

        useEffect(() => {
            let fixedAdInterval = null;
            if (!isDisabledFixedAds) {
                waitUntil(
                    isPubadsReady,
                    () => {
                        if (showFixedAd && !closeFixedAd && !overlayOpen) {
                            fixedAdInterval = setInterval(() => {
                                // set count before attempting to refresh in case there is an error
                                setCount((x) => x + 1);
                                window.googletag.pubads().refresh([
                                    window.googletag.pubads().getSlots().find((x) => x.getSlotId().getDomId() === 'div-gpt-ad-' + slotId),
                                ]);

                            }, interval);
                        }

                        if ((!showFixedAd && count > 1) || count === refreshLimit || closeFixedAd || overlayOpen) {
                            clearInterval(fixedAdInterval);
                        }

                    },
                    300,
                    10000,
                    // eslint-disable-next-line no-console
                    () => { console.error('Fixed Ad Failed to load'); },
                );
            }
            return () => (!isDisabledFixedAds && clearInterval(fixedAdInterval));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [count, closeFixedAd, showFixedAd, overlayOpen]);
    }

    useEffect(() => () => {
        // we need to manually remove this from pubads on unmount so that any callbacks for bids/post ad render don't error
        if (isPubadsReady()) {
            const slot = window.googletag.pubads().getSlots().find((x) => x.getSlotId().getDomId() === 'div-gpt-ad-' + slotId);
            if (slot) {
                window.googletag.pubads().clear([slot]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStickyAdClose = useCallback(async (event) => {
        sendClick('closeStickyAdClick', event);
        dispatch(srpFixedAdDuck.creators.setKeys({
            closeFixedAd: true,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const adPos = 'top';

    const sizes = useMemo(() => {
        if (isXs) {
            return _get(fixedAdSizes, [adPos, 'xs']);
        }

        if (isComputer) {
            return _get(fixedAdSizes, [adPos, 'lg']);
        }
        return _get(fixedAdSizes, [adPos, 'default']);
    }, [isXs, adPos, isComputer]);

    const adContainerHeight = isXs ? 50 : 90;
    const adContainerWidth = isXs ? 320 : 728;
    let targeting = {
        pos: adPos,
        prd: 'rc12',
    };

    if (isKbbBranded) {
        slotId = 'kbbAdsLeaderboard';
        targeting = {
            pos: 'top',
        };
    }

    // on KBB SRP listen for window event that may tell the ad to collapse and hide (but still render the slot)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isKbbAdHidden = isKbbBranded ? useMessageEvent(slotId) : false;

    const isAdHidden = !showFixedAd || closeFixedAd || isKbbAdHidden;

    const classNames = clsx(className, {
        'display-flex': true,
        'justify-content-center': true,
        'padding-horizontal-2': true,
        hidden: isAdHidden,
    });

    const style = {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: adContainerHeight,
        overflow: 'hidden',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 3,
    };

    if (!adsDisabled) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAutoRefreshTimer();
    }

    if (disableAd) {
        return null;
    }

    const renderFixedAd = hasDom() && !isAdHidden && hasResults && scrolled;

    /*
     * See FixedAdWayPointContainer and SearchResultsPageContainer to see logic of when to display & refresh the FixedAd
     * showFixedAd & closedFixedAd are stored in the fixedAd store and controlled by FixedAdWaypointContainer actions
     * do not render ad on mobile until the user scrolls
     */
    return renderFixedAd && (
        <DisableAdsFeatureFlag>
            <div
                className={classNames}
                data-cmp={cmpId}
                id={cmpId}
                style={style}
            >
                <div className="display-flex gap-1 align-items-start">
                    <div style={{ minWidth: adContainerWidth }}>
                        <AtcAdSlot
                            key={slotId + adPos}
                            slotId={slotId}
                            size={sizes}
                            targeting={targeting}
                            className="margin-vertical-0"
                            showLabel={false}
                            showPlaceholder={false}
                        />
                    </div>
                    <CloseButton
                        onClick={handleStickyAdClose}
                    />
                </div>
            </div>
        </DisableAdsFeatureFlag>
    );
}

export default memo(FixedAdContainer);
