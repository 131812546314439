import React from 'react';

import { connect } from 'react-redux';

import _get from 'lodash/get';

import { Alert } from 'reaxl';

import { srpExpiredListingMessageDuck, srpFiltersDuck } from '@/ducks/srp';

// this component renders an alert message on the SRP when redirected from an expired listing
// that does not contain a search engine marketing "sem" param in the expired listing URL.
// We are leveraging the make, model, zip and redirectExpiredPage=1
export function ExpiredListingRedirectAlertMessageContainer({
    make,
    model,
    redirectExpiredPage,
}) {
    const vehicleMessage = make || model ? `${make} ${model}` : 'vehicle';

    return !!redirectExpiredPage && (
        <Alert
            bsStyle="warning"
            data-cmp="expiredListingRedirectAlertMessageContainer"
        >
            <span className="text-bold">
                {`We're sorry, the ${vehicleMessage} you were looking for is no longer available.`}
            </span>
            <p>
                Here are vehicles you may be interested in.
            </p>
        </Alert>
    );
}

const mapStateToProps = (state) => {
    const {
        makeCodeList,
        modelCodeList,
    } = _get(state, 'requestParams', {});

    const filtersLabels = srpFiltersDuck.selectors.getLabelsByValues(state);
    const make = _get(filtersLabels, ['makeCodeList', makeCodeList], '');
    const model = _get(filtersLabels, [`modelCodeList|${makeCodeList}`, modelCodeList], '');

    return {
        redirectExpiredPage: srpExpiredListingMessageDuck.selectors.getRedirectExpiredPage(state),
        make,
        model,
    };
};

export default connect(mapStateToProps)(ExpiredListingRedirectAlertMessageContainer);
