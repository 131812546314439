// on a private seller SRP initial load - boost the radius up to next available radius option
export default function withPrivateSellerRadiusBoost(config) {
    return async (ctx) => {
        const { cookies, data, query } = ctx;

        const isPrivateSellerSrp = typeof query?.sellerType === 'string' && query.sellerType === 'p';
        const hasSelectedSearchRadius = (data?.currentUrl || '').includes('searchRadius');
        const searchRadiusCookie = cookies?.get('ATC_USER_RADIUS') || '';

        if (typeof window === 'undefined' && isPrivateSellerSrp && !hasSelectedSearchRadius && !searchRadiusCookie) {

            const defaultSearchRadius = typeof query.searchRadius === 'number' ? query.searchRadius : 50;
            const boostedRadius = defaultSearchRadius * 2;
            let updatedRadius;

            // for invalid boosted radi results, bump up or cap off at next valid radius option
            if (boostedRadius === 20) {
                updatedRadius = 25;
            } else if (boostedRadius === 150) {
                updatedRadius = 200;
            } else if (boostedRadius > 500) {
                updatedRadius = 500;
            } else {
                updatedRadius = boostedRadius;
            }

            ctx.query = {
                ...ctx.query,
                searchRadius: updatedRadius,
            };
        }
    };
}
