import fordSeoUrl from './seo-config-url/branded/ford';

export default {
    ford: [
        {
            content: {
                title: '',
                body: `With the Ford Blue Advantage shopping tool, search for certified used trucks based on preferred cab size, including crew cab and extended cab options. Looking for a black or blue truck? Filter by exterior and interior colors. Search by certain truck manufacturers and models, too. <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/truck/ford'}">Shop all certified used Ford trucks</a>.`,
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: 'TRUCKS',
                makeCode: undefined,
                modelCode: undefined,
            },
        },
        {
            content: {
                title: '',
                body: `Shop certified used Ford trucks backed by the Ford Blue Advantage program. Find certified used <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/f150'}">F-150<sup>®</sup> trucks</a>, <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/f250'}">F-250<sup>®</sup> trucks</a>, and more. Look for the Gold Certified badge for Ford trucks that are no more than six years old, have less than 80,000 miles, and passed a 172-point inspection, since only Ford models can become Gold certified.`,
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: 'TRUCKS',
                makeCode: 'FORD',
                modelCode: undefined,
            },
        },
        {
            content: {
                title: '',
                body: `Search certified used SUVs and crossovers for sale with the Ford Blue Advantage shopping tool. Within Features, filter to find 3rd-row SUVs and those with remote start or a backup camera. Looking for an all black SUV? Choose specific interior and exterior colors. Shop by make, like reviewing only <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/suv-crossover/ford'}">Ford SUVs and crossovers</a>, or by model, such as the <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/explorer'}">Ford Explorer</a>.`,
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: 'SUVCROSS',
                makeCode: undefined,
                modelCode: undefined,
            },
        },
        {
            content: {
                title: '',
                body: 'Discover a range of certified used vans and minivans, all backed by the Ford Blue Advantage Program. These vehicles are perfect for large families, transporting goods, or traveling in big groups. For long journeys, both vans and minivans offer ample legroom, reclining seats, and entertainment systems in select models, all designed with your comfort in mind. Transit vans also offer flexibility for getting the job done with lots of options to suit your business needs. Additionally, their spacious interiors make them highly accessible, as both minivans and larger gans can easily accommodate wheelchair lifts and ramps. Vans offers an unparalleled interior space that provides flexibility when it comes to passenger and cargo needs. Explore the available models of vans and minivans through the Ford Blue Advantage Program today.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: 'VANMV',
                makeCode: undefined,
                modelCode: undefined,
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford F-150<sup>®</sup> trucks backed by the Ford Blue Advantage program. Search by specific trims, like King Ranch and Platinum. Looking for a SuperCrew<sup>®</sup> or Super Cab? Filter truck cab sizes by crew cab and extended cab.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'F150PICKUP',
            },
        },
        {
            content: {
                title: '',
                body: 'Search certified Ford F-250<sup>®</sup> trucks and shop by trim styles like the Lariat and XLT. Looking for a diesel Ford F-250<sup>®</sup>? Use the Fuel Type filter to find diesel engines only. If you are looking for a single cab, search for regular cab trucks only under the Truck Cab Size filter.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'F250',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Bronco SUVs backed by the Ford Blue Advantage program. The off-road-ready Bronco returned to the Ford lineup in 2021 after a 25-year absence, and it has taken the trails by storm. Available in both 2-door and 4-door variants, the Bronco comes in multiple trims, each with a unique character and purpose. Lucky buyers may be able to find one of the rare 2021 First Edition models for sale — but any Bronco you find will have 4-wheel drive. Be ready for fun. Search Base, Badlands, Big Bend, Black Diamond, Everglades, Outer Banks, Raptor, Wildtrak, and First Edition trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'BRON',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford EcoSport subcompact crossover SUVs backed by the Ford Blue Advantage program. The Ford EcoSport only lasted from the 2018 to 2022 model years in the United States, but it had a longer history in its native Brazil. The EcoSport anchored Ford’s lineup as its most affordable SUV. Equipped with a 2.0-liter engine (turbocharged in SE, SES, and Titanium trims), the EcoSport delivers impressive fuel economy and a roomy cabin (for a subcompact). Commuters, first-time buyers, and city dwellers will appreciate the EcoSport’s affordability and tidy dimensions. Search S, SE, SES, and Titanium trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'FORDECOSPT',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Edge midsize crossover SUVs backed by the Ford Blue Advantage program. The Ford Edge has been at the center of the Ford crossover SUV lineup since 2007. This 2-row crossover has been produced in two generations (2007 to 2015, 2016 to 2023), built in Canada for North American consumption. Edge models feature EcoBoost engines from 2.0-liter 4-cylinder units up to 2.7-liter V6 powerplants, for a choose-your-own-adventure style of driving. Search for SE, SEL, ST-Line, Titanium, and ST trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'EDGE',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Escape compact crossover SUVs backed by the Ford Blue Advantage program. One of the most popular compact crossovers on the market, the Ford Escape is a versatile, roomy, and fun-to-drive SUV. Over the course of its production, the Escape has been available as a gasoline-only vehicle, a hybrid, and a plug-in hybrid, all searchable. Both front-wheel drive and all-wheel drive Escape models are for sale. Search for S, SE, SE Sport, SEL, Titanium, XLT, XLS, and Limited trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'ESCAPE',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Expedition full-size SUVs backed by the Ford Blue Advantage program. The Expedition is the biggest SUV in the current Ford lineup, with three rows of seating and robust body-on-frame construction. It also has style and grace, and a broad range of configurations. Each model comes with a 3.5-liter EcoBoost V6 engine and 10-speed automatic transmission with either rear-wheel drive or 4-wheel drive. The Expedition Max adds 9.1 inches to the wheelbase and 11.9 inches to the overall length of the vehicle, increasing cargo capacity by 16 cubic feet or more. Search XL, XLT, XLS, Limited, Platinum, King Ranch, Timberline, and Eddie Bauer trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'EXPEDI',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford F-350 full-size heavy-duty pickup trucks backed by the Ford Blue Advantage program. The F-350 is one of Ford’s heavy-duty full-size pickup trucks, slotting in the lineup just above the F-250 with which it shares many characteristics. While it makes a great work truck, the F-350 is also a smart choice for families who haul their campers, boats, and other recreational needs. Available in SuperCab and Crew Cab with gasoline or diesel powerplants and rear-wheel drive or 4-wheel drive, the versatile F-350 is a huge truck with giant capability. Search XL, XLT, Lariat, Limited, FX4, Platinum, King Ranch, Harley-Davidson, and Cabela’s trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'F350',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Fusion midsize sedans backed by the Ford Blue Advantage program. One of the world’s most popular midsize sedans, the Fusion was sold in the United States from the 2006 to 2020 model years. A 4-door, front-engine, front-wheel drive or all-wheel drive sedan, it came with a variety of powertrains over its lifetime, including a 1.5-liter EcoBoost, a 2.0-liter EcoBoost, a 2.3-liter 4-cylinder, a 3.0-liter V6, a 3.5-liter V6, a hybrid, and a plug-in hybrid (the Fusion Energi). Search for S, SE, SEL, Sport, Titanium, Platinum, Energi, Energi SE, Energi Titanium, and Energi Platinum trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'FUSION',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Mustang coupes and convertibles backed by the Ford Blue Advantage program. The Ford Mustang pioneered the pony car category when it debuted in 1964. Through generations of evolution, the Mustang has developed into a beloved mainstay of the American automotive landscape, while pushing forward its styling, performance, and handling to dominate the muscle car category. The range of Mustang powertrains can be overwhelming, from a turbocharged 4-cylinder to a naturally aspirated V8, there’s a Mustang for every driver. Search EcoBoost Fastback, EcoBoost Premium Fastback, GT Fastback, GT Premium Fastback, Mach 1, Mach 1 Premium, and Shelby GT500 trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'MUST',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ford Ranger midsize pickup trucks backed by the Ford Blue Advantage program. Ford revived a favorite pickup nameplate in 2019. The Ranger name was last applied to a compact pickup from 1983 to 2011. The new Ranger is bigger than the old one, designed to compete in a newly hot market. Available in SuperCab and SuperCrew configurations, the Ranger comes with a 2.3-liter EcoBoost engine and rear-wheel drive or 4-wheel drive. Search XL, XLT, Lariat, STX Special Edition, Tremor, and Black Appearance trim levels and packages.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'RANGER',
            },
        },
        {
            content: {
                title: '',
                body: 'Search certified Ford Explorers for sale and backed by Ford Blue Advantage. Filter by trim levels, like the ST, XLT, and Timberline. Shop by exterior and interior colors. Within Features, filter by Interior Features, like a 3rd row, by Safety Features, and more.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'FORD',
                modelCode: 'EXPLOR',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Chevrolet Silverado 1500 full-size light-duty pickup trucks backed by the Ford Blue Advantage program. The Silverado is one of Chevrolet’s most popular trucks known for its reliability, perfect for hauling heavy loads with a towing capacity of over 13,000 pounds. Available in Regular Cab, Double Cab, and Crew Cab, the Silverado is able to meet your specific driving needs. The truck has five different engine options with both rear-wheel drive and 4-wheel drive available. Search WT, Custom, Custom Trail Boss, LT, RST, LTZ LT Trail Boss, and High Country trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'CHEV',
                modelCode: 'CHEV150',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Chevrolet Equinox compact SUVs backed by the Ford Blue Advantage Program. The Equinox is one of Chevrolet’s bestselling models, known for its budget-friendly pricing as well as its roomy interior. It’s the perfect family SUV with spacious second-row seating and Chevy Safety Assist, which comes standard with automatic emergency braking and lane-keep assist. The Chevrolet Equinox is powered by a 175-hp 4-cylinder engine with a 6-speed automatic transmission, available in both front-wheel drive and all-wheel drive. Search LS, LT, RS, and Premier trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'CHEV',
                modelCode: 'EQUINOX',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Chevrolet Tahoe full-size 3-row SUVs backed by the Ford Blue Advantage Program. The Tahoe is known to provide a comfortable ride with a sturdy build, using the Silverado’s ladder-frame construction. With lots of space and seating for up to 9 people, the Tahoe is the perfect SUV for a large family. The Chevrolet Tahoe has three available engine options that connect to a 10-speed automatic transmission including the 5.3-liter V8 engine, the 6.2-liter V8 engine, and the 3.0-liter turbocharged diesel inline-6 engine. Rear-wheel drive comes standard with the option to upgrade to 4-wheel drive (Z71 has 4WD standard). Search LS, LT, RST, Z71, Premier, and High Country trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'CHEV',
                modelCode: 'TAHOE',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Honda Civic sedans, coupes, and hatchbacks, all backed by the Ford Blue Advantage Program. The Civic is one of Honda’s best-selling models, known for its longevity and budget-friendly pricing. The Civic is the perfect car for almost everybody as it has an extremely versatile target audience due to its fuel efficiency, low maintenance costs, and expansive model range. The Honda Civic has two engine options that connect to a continuously variable automatic transmission including a 2.0-liter 4-cylinder and a 1.5-liter turbocharged 4-cylinder engine. Search LX sedan, LX hatchback, Sport sedan, Sport hatchback, EX sedan, EX-L hatchback, Touring sedan, and Sport Touring hatchback trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'HONDA',
                modelCode: 'CIVIC',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Honda CR-V compact crossover SUVs backed by the Ford Blue Advantage Program. The CR-V stands out for its fuel economy, resale value, and reliable safety features, making it a popular Honda model that checks all the boxes. Perfect for families and outdoor lovers, the CR-V has a spacious interior that can comfortably seat five passengers with plenty of cargo space that can expand with fold-down second-row seating. The Honda CR-V is powered by a 1.5-liter turbocharged 4-cylinder engine with an automatic transmission, available in both front-wheel drive and all-wheel drive. Search LX, EX, EX-L, Sport Hybrid, Sport-L Hybrid, and Sport Touring Hybrid trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'HONDA',
                modelCode: 'CRV',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop  certified GMC Sierra 1500 full-size pickup truck backed by the Ford Blue Advantage Program. The Sierra 1500 stands out for its performance and comfort, while also offering a luxury feel that is sure to impress. Perfect for utility, the Sierra 1500 features a six-function MultiPro tailgate that allows for easy loading and unloading of cargo. The GMC Sierra 1500 has five available engine options paired with automatic transmission, including a 2.7-liter turbocharged four-cylinder, V6, 5.3-liter V8, 6.2-liter V8, and Duramax turbo-diesel engine, with a range of customization options available to suit your needs. Safety features such as traction control, stability control, and tire pressure monitoring system come standard. Search Pro, SLE, Elevation, SLT, AT4, Denali, AT4X, and Denali Ultimate trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'GMC',
                modelCode: '15SIPU4WD',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Jeep Grand Cherokee midsize SUVs backed by the Ford Blue Advantage Program. The Grand Cherokee is known for its off-roading capabilities, towing capacity, and its advanced safety features. The Grand Cherokee is has a high-quality interior including spacious seating, advanced technology features, and options like 4-zone climate control along with heated and cooled rear seating. The Jeep Grand Cherokee is powered by a 3.6-liter V6 engine delivering 293 horsepower with an 8-speed automatic transmission, available in both front-wheel drive and all-wheel drive. Search Laredo, Limited, Overland, and Summit trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'JEEP',
                modelCode: 'JEEPGRAND',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Toyota RAV4 compact crossover SUV backed by the Ford Blue Advantage Program. The RAV4 is Toyota’s best-selling SUV, known for its functionality and dependability. With its excellent fuel economy, spacious cabin, and extensive array of features, the RAV4 is a great choice for anyone. Standard features include adaptive cruise control, a touchscreen display, lane departure alert, and a pre-collision system. The Toyota RAV4 is powered by a 2.5-liter 4-cylinder engine with an 8-speed automatic transmission, available in both front-wheel drive and all-wheel drive. Search LE, XLE, XLE Premium, Adventure, Limited, and TRD Off-Road trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'TOYOTA',
                modelCode: 'RAV4',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Toyota Tacoma midsize pickup trucks backed by the Ford Blue Advantage program. The Tacoma is one of Toyota’s most notorious off-roading vehicles that is easy to maneuver due to its practical size. The midsize pickup is a reliable vehicle with safety features such as automatic emergency braking, adaptive cruise control, and forward-collision warning. Available engines include a 2.7-liter 4-cylinder as well as a 3.5-liter V6 option. Search SR, SR5, TRD Sport, TRD Off-Road, Limited, Trail Special Edition, and TRD Pro trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'TOYOTA',
                modelCode: 'TACOMA',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Ram 1500 full-size light-duty pickup trucks backed by the Ford Blue Advantage program. The Ram 1500 is known for its durability, fuel economy, and smooth ride. Available in Regular Cab, Crew Cab, Quad Cab, and Mega Cab with 3.6-liter V-6 or 5.7-liter V-8 engines available, the Ram 1500 has the flexibility to meet your needs with engineering that is known to last. Search Tradesman, Tradesman HFE, Big Horn/Lone Star, Laramie, TRX, Rebel, Limited Longhorn, and Limited trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'RAM',
                modelCode: 'RM1500',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Nissan Rogue compact crossover SUVs backed by the Ford Blue Advantage program. The Rogue is the perfect family-friendly SUV, equipped with Nissan’s Safety Shield 360 system which includes six advanced monitors that capture the front, back, and sides of the vehicle while you are driving. The interior of the compact SUV is very spacious with plenty of cargo space. Available engines include the 2.5-liter 4-cylinder engine or the 1.5-liter turbocharged inline-3 engine. Search S, SV, SV Midnight Edition, SL, and Platinum trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'NISSAN',
                modelCode: 'ROGUE',
            },
        },
        {
            content: {
                title: '',
                body: 'Shop certified Jeep Wrangler mid-size off-road SUVs backed by the Ford Blue Advantage program. The Wrangler is one of Jeeps most popular models, known for its off-roading capabilities and unique design with a detachable roof and doors. Perfect for an avid traveler, the Wrangler comes standard with 4-wheel drive, skid plates, and hill-start assist. Available engines include the 3.6-liter V6, 2.0-liter turbocharged 4-cylinder, and 3.0-liter V6 turbo diesel. Search Sport, Sport S, Willys, Sahara, Rubicon, Rubicon X, and Rubicon 392 trim levels.',
                disclaimer: '',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                makeCode: 'JEEP',
                modelCode: 'WRANGLER',
            },
        },
        {
            content: {
                title: 'Money Back Guarantee Program Details',
                body: 'Buy your Ford Blue Advantage vehicle with the peace of mind of knowing it is backed by our dealer\'s commitment to help you choose the right car for you. As part of the Ford Blue Advantage certification program, your vehicle comes with a 14 day or 1,000 mile money back guarantee - which ever comes first. That means you can return your Ford Blue Advantage certified vehicle to the Dealer that you purchased it from and work together to find another that better suits your needs. See your dealer for important program guidelines and restrictions*.',
                disclaimer: '*Only available at participating Ford Dealers. Vehicle must be returned within 14 days/1,000 miles (whichever comes first) from the original sales date and mileage as stated on bill of sale and returned in same condition as sold (excludes normal wear & tear). Ask your dealer for important rules or go to <a href="https://www.ford.com/money-back">ford.com/money-back</a>',
            },
            filterOptions: {
                vehicleStyleCode: undefined,
                vehicleExchange: 'true',
            },
        },
        {
            content: {
                title: '',
                body: 'Buying a car on a budget? Search certified used cars under $20,000 that are backed by the Ford Blue Advantage program. Purchase confidently knowing that all Ford Blue Advantage certified vehicles must pass a multi-point inspection, unlike cheap used cars you might find elsewhere.',
                disclaimer: '',
            },
            filterOptions: {
                maxPrice: '20000',
            },
        },
        {
            content: {
                title: '',
                body: `Shop certified used commercial vehicles backed by the Ford Blue Advantage program and find work vans and Super Duty® trucks. Looking for a specific work van? Consider the <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/transit-250'}">Ford Transit 250</a>, <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/transit-350'}">Transit 350</a>, or the <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/ford/transit-connect'}">Transit Connect</a>.`,
                disclaimer: '',
            },
            filterOptions: {
                vehicleUseType: 'COMMERCIAL',
            },
        },
        {
            content: {
                title: '',
                body: `Looking for a vehicle with good gas mileage? Consider a certified used hybrid vehicle backed by the Ford Blue Advantage program. Find hybrid cars, trucks, and SUVs for sale or search for an <a href="${fordSeoUrl.prod.rootUrl + '/cars-for-sale/all/electric'}">electric vehicle</a>.`,
                disclaimer: '',
            },
            filterOptions: {
                fuelTypeGroup: 'HYB',
            },
        },
        {
            content: {
                title: '',
                body: 'Your options to buy an efficient used electric vehicle are increasing thanks to the continuous release of new electric vehicle models. Find popular EV body styles like sedans, SUVs, and trucks with various driving ranges and features. Shopping Tips: When choosing an EV, be sure to ask how much battery life is left, if there is any battery warranty still available, to see the battery health report, and to see the maintenance history of the vehicle.',
                disclaimer: '',
            },
            filterOptions: {
                fuelTypeGroup: 'ELE',
            },
        },
    ],
};
