import { usePageEvent } from '@bonnet/next/use-page-event';

import useSendSRPPageEvent from '@/analytics/useSendSRPPageEvent';

function SrpPageEventsContainer() {
    const { onPageEnter, isServerRendered } = usePageEvent('srpPage', { priority: 1 });

    const sendSRPPageEvent = useSendSRPPageEvent();

    onPageEnter(async () => {
        await sendSRPPageEvent({
            isInitialLoad: isServerRendered,
        });
    });

    return null;
}

export default SrpPageEventsContainer;
