import React from 'react';

import { useSelector } from 'react-redux';

import { AnalyticsEngine } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import inventoryAnalytics from '@/analytics/inventoryAnalyticsHandlers';
import nativeDealAnalytics from '@/analytics/nativeDealAnalyticsHandlers';
import ownerAnalyticsHandlers from '@/analytics/ownerAnalyticsHandlers';
import psxAnalyticsHandlers from '@/analytics/psxAnalyticsHandlers';
import srpAnalyticsHandlers from '@/analytics/srpAnalyticsHandlers';
import useAnalyticsPageEventListener from '@/analytics/useAnalyticsPageEventListener';
import useDataLayer from '@/analytics/useDataLayer';

import { birfDuck, globalConfigsDuck } from '@/ducks';

import { CookieConsentModule } from '@/modules';

function SRPAnalytics({ totalBoostCount }) {
    const {
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
        oneTrust: [isOneTrustEnabled],
        optimizely_edge: [enableOptimizelyEdge],
    } = useFeatures([
        'disable_thirdparty',
        'google_analytics',
        'oneTrust',
        'optimizely_edge',
    ]);

    const hasSuppressedCookies = useSelector(CookieConsentModule.duck.selectors.selectHasSuppressedGroup) && isOneTrustEnabled;
    const telmetricsKey = useSelector(globalConfigsDuck.selectors.getDuckState)?.telmetricsKey;

    const dataLayer = useDataLayer({ totalBoostCount });
    const analyticsPageEventListener = useAnalyticsPageEventListener();
    const dataIsland = useSelector(birfDuck.selectors.getPageData);
    const analyticsDataIsland = {
        ...dataIsland,
    };

    const analyticsProps = {
        google: {
            delay,
        },
        disabled: hasSuppressedCookies,
        eventListener: analyticsPageEventListener,
        instanceId: dataLayer?.page?.pixallPageInstanceId,
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => analyticsDataIsland,
        value: {
            ...globalAnalyticsHandlers,
            ...inventoryAnalytics,
            ...ownerAnalyticsHandlers,
            ...psxAnalyticsHandlers,
            ...srpAnalyticsHandlers,
            ...nativeDealAnalytics,
        },
        option: {
            isOptimizelyEdge: enableOptimizelyEdge,
            disableGTM: disableGtm || disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            disablePixall: disableThirdParty,
            telmetricsKey,
        },
    };

    return (<AnalyticsEngine {...analyticsProps} />);
}

export default React.memo(SRPAnalytics);
