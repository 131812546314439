import { fetchJSON } from '@bonnet/core/fetch';

export default async (query = {}) => {
    const modelYearsData = await fetchJSON('/rest/lsc/crawl/modelyears', {
        circuitBreaker: {
            id: 'crawlPathModelYears',
            timeout: 3000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for /rest/lsc/crawl/modelyears is open',
            },
        },
        query,
        headers: {
            'X-fwd-svc': 'atc',
        },
    });
    return modelYearsData || {};
};
