import { fetchJSON } from '@bonnet/core/fetch';

export default async (query = {}) => {
    const trimData = await fetchJSON('/rest/lsc/crawl/trims', {
        circuitBreaker: {
            id: 'crawlPathTrim',
            timeout: 3000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for /rest/lsc/crawl/trims is open',
            },
        },
        query,
        headers: {
            'X-fwd-svc': 'atc',
        },
    });
    return trimData || {};
};
