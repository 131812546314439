const add0IfLessThan10 = (number) => ('0' + number).slice(-2);

export default function formattedLastExecuteTime(date = new Date()) {
    const day = date.getDate();
    // Get month (1-12)
    const month = add0IfLessThan10(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = add0IfLessThan10(date.getHours());
    const minutes = add0IfLessThan10(date.getMinutes());
    const seconds = add0IfLessThan10(date.getSeconds());

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}
