import _get from 'lodash/get';

import {
    buildCollapsed,
    buildDynamicFilterSubTypeOption,
    buildFilterMileageOption,
    buildFilterOption,
    buildFilterOptionGroup,
    buildFilterYearOption,
    buildFordSelected,
    buildFordValue,
    buildModelSeriesFilterOption,
    buildPackagesFilterOption,
    buildTrimFilterOption,
    buildValue,
} from '@/utilities/getReferenceFiltersData';

// Ford Filters vehicleUseType, vehicleExchange
const buildFilterReferenceOption = async (lscData, queryList) => {
    const filterReferenceOptions = {
        location: {
            collapsed: true,
            label: 'Location',
            name: 'location',
        },
        zip: {
            protected: true,
            label: 'ZIP Code',
            name: 'zip',
            value: '',
        },
        searchRadius: {
            collapsed: false,
            label: 'Distance',
            name: 'searchRadius',
            options: await buildFilterOption('searchRadiuses', lscData, 'searchRadius', queryList),
            value: buildValue(queryList.searchRadius),
        },
        marketExtension: {
            title: 'Include Long-Distance Delivery',
            name: 'marketExtension',
            visible: true,
            value: '',
        },
        vehicleUseType: {
            collapsed: await buildCollapsed(queryList?.vehicleUseType),
            label: 'Vehicle Use Type',
            name: 'vehicleUseType',
            options: [
                {
                    label: 'Personal',
                    value: 'PERSONAL',
                },
                {
                    label: 'Commercial',
                    value: 'COMMERCIAL',
                },
            ],
            value: buildValue(queryList?.vehicleUseType),
        },
        vehicleExchange: {
            collapsed: 'false',
            label: 'Money Back Guarantee',
            name: 'vehicleExchange',
            options: [
                {
                    label: 'Only show vehicles eligible for the Money Back Guarantee',
                    selected: await buildFordSelected(queryList),
                    value: 'true',
                },
            ],
            value: buildFordValue(queryList),
        },
        yearRange: {
            collapsed: true,
            label: 'Year',
            name: 'yearRange',
            options: await buildFilterYearOption('years', lscData, 'yearRange', queryList),
            value: [],
        },
        listingType: {
            collapsed: false,
            label: 'Condition',
            name: 'listingType',
            options: await buildFilterOption('listingTypes', lscData, 'listingType', queryList),
            value: buildValue(queryList?.listingType),
        },
        driveGroup: {
            collapsed: await buildCollapsed(queryList?.driveGroup),
            label: 'Drive Type',
            name: 'driveGroup',
            options: await buildFilterOption('driveGroup', lscData, 'driveGroup', queryList),
            value: buildValue(queryList?.driveGroup),
        },
        priceRange: {
            collapsed: true,
            label: 'Price',
            name: 'priceRange',
            options: [
                {
                    disabled: false,
                    label: 'Min',
                    value: 'minPrice',
                },
                {
                    disabled: false,
                    label: 'Max',
                    value: 'maxPrice',
                },
            ],
            value: [],
        },
        mileage: {
            collapsed: await buildCollapsed(queryList?.mileage),
            label: 'Mileage',
            name: 'mileage',
            options: await buildFilterMileageOption(queryList?.mileage),
            value: buildValue(queryList?.mileage),
        },
        vehicleStyleCode: {
            collapsed: await buildCollapsed(queryList?.vehicleStyleCode),
            label: 'Body Style',
            name: 'vehicleStyleCode',
            options: await buildFilterOption('vehicleStyleCode', lscData, 'vehicleStyleCode', queryList),
            value: buildValue(queryList?.vehicleStyleCode),
        },
        fuelTypeGroup: {
            collapsed: await buildCollapsed(queryList?.fuelTypeGroup),
            label: 'Fuel Type',
            name: 'fuelTypeGroup',
            options: await buildFilterOption('fuelTypeGroup', lscData, 'fuelTypeGroup', queryList),
            value: buildValue(queryList?.fuelTypeGroup),
        },
        mpgRange: {
            collapsed: await buildCollapsed(queryList?.mpgRange),
            label: 'Fuel Economy',
            name: 'mpgRange',
            options: await buildFilterOption('mpgRange', lscData, 'mpgRange', queryList),
            value: buildValue(queryList?.mpgRange),
        },
        makeCode: {
            collapsed: false,
            label: 'Make',
            name: 'makeCode',
            options: await buildFilterOption('makeCode', lscData, 'makeCode', queryList),
            value: buildValue(queryList?.makeCode),
        },
        extColorSimple: {
            collapsed: await buildCollapsed(queryList?.extColorSimple),
            label: 'Exterior Color',
            name: 'extColorSimple',
            options: await buildFilterOption('extColorGroups', lscData, 'extColorSimple', queryList),
            value: buildValue(queryList?.extColorSimple),
        },
        intColorSimple: {
            collapsed: await buildCollapsed(queryList?.intColorSimple),
            label: 'Interior Color',
            name: 'intColorSimple',
            options: await buildFilterOption('intColorGroups', lscData, 'intColorSimple', queryList),
            value: buildValue(queryList?.intColorSimple),
        },
        transmissionCode: {
            collapsed: await buildCollapsed(queryList?.transmissionCode),
            label: 'Transmission',
            name: 'transmissionCode',
            options: await buildFilterOption('transmissionGroups', lscData, 'transmissionCode', queryList),
            value: buildValue(queryList?.transmissionCode),
        },
        engineDisplacement: {
            collapsed: await buildCollapsed(queryList?.engineDisplacement),
            label: 'Engine Displacement',
            name: 'engineDisplacement',
            options: await buildFilterOption('engineDisplacements', lscData, 'engineDisplacement', queryList),
            value: buildValue(queryList?.engineDisplacement),
        },
        vehicleHistoryType: {
            collapsed: await buildCollapsed(queryList?.vehicleHistoryType),
            label: 'Vehicle History',
            name: 'vehicleHistoryType',
            options: await buildFilterOption('vehicleHistoryOptions', lscData, 'vehicleHistoryType', queryList),
            value: buildValue(queryList?.vehicleHistoryType),
        },
        homeServices: {
            collapsed: await buildCollapsed(queryList?.homeServices),
            label: 'Dealer Home Services',
            name: 'homeServices',
            options: await buildFilterOption('homeServices', lscData, 'homeServices', queryList),
            value: buildValue(queryList?.homeServices),
        },
        featureCode: {
            collapsed: await buildCollapsed(queryList?.featureCode),
            label: 'Features',
            name: 'featureCode',
            options: await buildFilterOption('featureCode', lscData, 'featureCode', queryList),
            optionsGroups: await buildFilterOptionGroup('featureCode', lscData, 'featureCode', queryList),
            value: buildValue(queryList?.featureCode),
        },
        engineCode: {
            collapsed: await buildCollapsed(queryList?.engineCode),
            label: 'Cylinders',
            name: 'engineCode',
            options: await buildFilterOption('engineCode', lscData, 'engineCode', queryList),
            value: buildValue(queryList?.engineCode),
        },
        dealType: {
            collapsed: await buildCollapsed(queryList?.dealType),
            label: 'Price Rating',
            name: 'dealType',
            options: await buildFilterOption('dealTypes', lscData, 'dealType', queryList),
            value: buildValue(queryList?.dealType),
        },
        doorCode: {
            collapsed: await buildCollapsed(queryList?.doorCode),
            label: 'Doors',
            name: 'doorCode',
            options: await buildFilterOption('doorCode', lscData, 'doorCode', queryList),
            value: buildValue(queryList?.doorCode),
        },
        sellerType: {
            collapsed: await buildCollapsed(queryList?.sellerType),
            label: 'Seller Type',
            name: 'sellerType',
            options: await buildFilterOption('sellers', lscData, 'sellerType', queryList),
            value: buildValue(queryList?.sellerType),
        },
    };

    return filterReferenceOptions || {};
};

export default async (ctx, isFilterGroups) => {
    const lscData = ctx?.data?.filters;
    const lscFilterGroupsData = ctx?.data?.filterGroups;
    const queryList = ctx?.query;
    const fuelTypeList = _get(lscData, 'fuelTypeGroup.options', []);
    const vehicleStyleCodeList = _get(lscData, 'vehicleStyleCode.options', []);

    const filterReferenceOption = await buildFilterReferenceOption(lscData, queryList);
    const modelListData = await buildModelSeriesFilterOption(lscData, 'modelCode', queryList, lscFilterGroupsData, isFilterGroups) || {};
    const trimList = await buildTrimFilterOption(lscData, 'trimCode', queryList, lscFilterGroupsData, isFilterGroups) || {};
    const packageList = await buildPackagesFilterOption(lscData, 'packages', queryList, lscFilterGroupsData, isFilterGroups) || {};
    const electricMileRangeData = await buildDynamicFilterSubTypeOption('electricMileRanges', lscData, 'electricMileRange', queryList?.electricMileRange, 'Electric Mile Range', fuelTypeList, 'ELE', queryList);
    const truckBedLengthData = await buildDynamicFilterSubTypeOption('truckBedLengths', lscData, 'truckBedLength', queryList?.truckBedLength, 'Truck Bed Length', vehicleStyleCodeList, 'TRUCKS', queryList);
    const truckCabSizeData = await buildDynamicFilterSubTypeOption('truckCabSizes', lscData, 'bodyStyleSubtypeCode', queryList?.bodyStyleSubtypeCode, 'Truck Cab Size', vehicleStyleCodeList, 'TRUCKS', queryList);

    const filterObj = {
        ...filterReferenceOption,
        ...(electricMileRangeData != null && electricMileRangeData) ? { electricMileRange: electricMileRangeData } : '',
        ...(truckBedLengthData != null && truckBedLengthData) ? { truckBedLength: truckBedLengthData } : '',
        ...(truckCabSizeData != null && truckCabSizeData) ? { bodyStyleSubtypeCode: truckCabSizeData } : '',
        ...(modelListData != null && modelListData?.modelCode) ? { modelCode: { ...modelListData.modelCode } } : '',
        ...(modelListData != null && modelListData?.seriesCode) ? { seriesCode: { ...modelListData.seriesCode } } : '',
        ...(trimList != null && trimList?.trimCode) ? { trimCode: { ...trimList.trimCode } } : '',
        ...(packageList != null && packageList) ? { ...packageList } : '',
    };
    return filterObj || {};
};
