import { fetchJSON } from '@bonnet/next/fetch';

import { keyMappings, translateKeys } from '@atc/bonnet-parameters';

import { brands } from 'reaxl-brand';

import { srpReuseActiveStateDuck } from '@/ducks/srp';

import addNewCarBoostData from './addNewCarBoostData';

export default function addSearchResultsPageData() {

    return async (ctx) => {
        const {
            // feature configs
            crawlPath: [enableCrawlPath, {
                trimData: enableTrimData,
                cityDataWithZip: enabledCityDataWithZip,
            }],
            spotlight: [isSpotlightEnabled],
            premium_spotlight: [isPremiumSpotlightEnabled, {
                multi_carousel_3: multiCarousel3,
                multi_carousel_9: multiCarousel9,
            }],
            // optimizely
            disable_spotlights: [disableSpotlight],
            disable_suggested_filters: [disableSuggestedFilters],
            enable_crawlpath_migration: [enableCrawlPathMigration],
            enable_new_car_boost: [enableNewCarBoost],
            default_num_records: [, { sortOptions: defaultNumRecordsOptions }],
        } = ctx.useFeatures([
            'crawlPath',
            'disable_spotlights',
            'disable_suggested_filters',
            'enable_crawlpath_migration',
            'enable_new_car_boost',
            'spotlight',
            'premium_spotlight',
            'default_num_records',
        ]);

        const state = ctx.store.getState();
        const reuseActiveState = srpReuseActiveStateDuck.selectors.getDuckState(state);

        const isKbbBrand = ctx.data?.brand === brands.KBB_BRAND;
        const isFordBrand = ctx.data?.brand === brands.FORD_BRAND;

        // listings endpoint //
        const listingsEndpoint = '/rest/lsc/listing';
        const lscSearchRequired = translateKeys(ctx.data.requestOptions.requiredRequestLSC.query, { target: 'lsc' });

        if (!disableSuggestedFilters) {
            Object.assign(lscSearchRequired, {
                includeSuggested: true,
            });
        }

        if (!!isFordBrand && !ctx.query?.numRecords) {
            Object.assign(lscSearchRequired, {
                numRecords: defaultNumRecordsOptions?.value,
            });
        }

        const listingsRequestOptions = {
            ...ctx.data.requestOptions.requiredRequestLSC,
            query: lscSearchRequired,
            circuitBreaker: {
                ...ctx.data.requestOptions.requiredRequestLSC.circuitBreaker,
                id: 'lsc_listing',
            },
        };

        if (typeof window === 'undefined') {
            listingsRequestOptions.responseInterceptor = (response, { json, cookies }) => {
                json._cookies = cookies;
            };
        }

        let numSpotlights = 3;
        if (isPremiumSpotlightEnabled) {
            if (multiCarousel3 || multiCarousel9) {
                numSpotlights = 13;
            } else {
                numSpotlights = 4;
            }
        }
        const lscSearchOptional = translateKeys(ctx.data.requestOptions.optionalRequestLSC.query, { target: 'lsc' });
        const lscRequestOptional = {
            ...ctx.data.requestOptions.optionalRequestLSC,
            query: { ...lscSearchOptional, numSpotlights },
        };

        ctx.fetchCompositeJSON.addFetch('base', fetchJSON(listingsEndpoint, listingsRequestOptions));

        // spotlights endpoint //
        const getSpotlightsEndpoint = (resultType) => `/rest/lsc/listing/spotlight/${resultType}`;
        const spotlightRequestOptions = {
            ...lscRequestOptional,
            circuitBreaker: {
                ...lscRequestOptional.circuitBreaker,
                id: 'spotlight_premium',
            },
        };
        const getNonPremiumSpotlightRequestOptions = (listingTier) => ({
            ...lscRequestOptional,
            query: {
                ...lscRequestOptional.query,
                numSpotlights: 6,
                listingTier,
            },
            circuitBreaker: {
                ...lscRequestOptional.circuitBreaker,
                id: `spotlight_${listingTier}`,
            },
        });

        if (isSpotlightEnabled && !disableSpotlight && !reuseActiveState) {
            ctx.fetchCompositeJSON.addFetch('spotlights', fetchJSON(getSpotlightsEndpoint('premiumresults'), spotlightRequestOptions));
            ctx.fetchCompositeJSON.addFetch('featuredSpotlights', fetchJSON(getSpotlightsEndpoint('results'), getNonPremiumSpotlightRequestOptions('featured')));
            ctx.fetchCompositeJSON.addFetch('standardSpotlights', fetchJSON(getSpotlightsEndpoint('results'), getNonPremiumSpotlightRequestOptions('standard')));
        }

        const shouldFetchNewCarBoost = enableNewCarBoost && !reuseActiveState;
        addNewCarBoostData(ctx, lscRequestOptional, shouldFetchNewCarBoost);

        // Only assign crawl path and trims reference fetches on initial server calls
        if (typeof window === 'undefined' && !enableCrawlPathMigration) {
            const translatedKeys = translateKeys(ctx.query, { target: 'lsc' });

            const { makeCode, modelCode, trimCode, year, vehicleStyleCode, driveGroup, minPrice, maxPrice, fuelTypeGroup, listingType } = translatedKeys;

            const hasMultipleSelections = (key) => Array.isArray(ctx.query[key]) && ctx.query[key].length > 1;
            const multipleCrawlParams = ['makeCode', 'seriesCode', 'modelCode', 'seriesCode', 'trimCode', 'listingType', 'vehicleStyleCode', 'bodyStyleSubtypeCode', 'featureCode', 'listingFeature', 'fuelTypeGroup', 'mpgRange'];
            const requestCrawlPath = !keyMappings.filter((x) => multipleCrawlParams.includes(x.lsc))
                .find((x) => hasMultipleSelections(x.lsc) || hasMultipleSelections(x.cs));

            // Trim URLs have trimCodelist in query and do not have year in url
            const isTrimUrl = !!trimCode && !year;

            // Year URLs have year in url and do not have trimCodeList in query
            const isYearUrl = !!year && !trimCode;

            // YEAR-MAKE-MODEL-TRIM URLs have all 4 params in the url
            const isYmmtUrl = !!(year && makeCode && modelCode && trimCode);
            const isAllCarsUrl = !makeCode && !modelCode && !trimCode;
            const isMakeUrl = !!(makeCode && !modelCode && !trimCode);
            const isModelUrl = !!(makeCode && modelCode && ((enableCrawlPath && enableTrimData) || !trimCode));

            // Disable some crawl path modules EXCEPT city list when having 2 filters including Body Style
            // This only applied for ATC and Ford
            const disableNonCitiesCrawlpath = !isKbbBrand && !!(vehicleStyleCode && (year || makeCode || modelCode || trimCode));

            const disableTrimList = isTrimUrl || isYearUrl || isYmmtUrl || disableNonCitiesCrawlpath;

            // Crawl path fetch conditions
            const shouldFetchTrimsData = (enableCrawlPath && enableTrimData) || !disableTrimList;
            const shouldFetchYearsData = isKbbBrand || (isModelUrl && !(isYearUrl || disableNonCitiesCrawlpath));

            const fbaShouldRenderCitiesList = !!(!makeCode && !vehicleStyleCode && !driveGroup && !minPrice && !maxPrice && !fuelTypeGroup && !year);

            const shouldFetchCrawlpaths = () => {
                // KBB: Should not render crawl paths if the listing type is 'NEW' or 'CERTIFIED' since the links will be noindexed
                if (isKbbBrand && (listingType === 'NEW' || listingType === 'CERTIFIED')) {
                    return false;
                }

                return requestCrawlPath;
            };

            // Only request any crawl path data if there are no multiple makeCodes or modelCodes in the searc
            // requests to /crawl/* endpoints should not include location params*
            const getCrawlPathsRequestOption = (citiesCrawlPath = false) => {
                // eslint-disable-next-line no-unused-vars
                const { city, state: s, zip, location, dma, searchRadius, ...rest } = lscRequestOptional?.query || {};

                // *excluding /crawl/cities on pages with a location param (i.e /atlanta-ga)
                const requestOptions = citiesCrawlPath && !ctx.match?.isNational ? lscRequestOptional : { ...lscRequestOptional, query: rest };

                return {
                    ...requestOptions,
                    circuitBreaker: {
                        ...requestOptions.circuitBreaker,
                        id: 'lsc_crawlpath',
                    },
                };
            };

            const crawlPathsRequestOptions = getCrawlPathsRequestOption();
            // change vehicle style codes for SUVs and Vans to supported values
            switch (crawlPathsRequestOptions.query.vehicleStyleCode) {
                case 'SUVCROSS': {
                    crawlPathsRequestOptions.query.vehicleStyleCode = 'SUV';
                    break;
                }
                case 'VANMV': {
                    crawlPathsRequestOptions.query.vehicleStyleCode = 'VANS';
                    break;
                }
                default:
                    break;
            }

            if (shouldFetchCrawlpaths()) {
                // This forces all the crawlpath links to have a minimum count
                crawlPathsRequestOptions.query.facetMinCount = 3;
                // Override Preview channel to behave as ATC for testing L2 Retailing
                if (crawlPathsRequestOptions.query.channel === 'ATC,PREVIEW') {
                    crawlPathsRequestOptions.query.channel = 'ATC';
                }

                if (!disableNonCitiesCrawlpath && isAllCarsUrl) {
                    // makesData is used to generate makes, model, trims list in CrawlPathFooter.jsx
                    ctx.fetchCompositeJSON.addFetches({
                        makesCrawlPaths: fetchJSON('/rest/lsc/crawl/makes', crawlPathsRequestOptions),
                    });
                }

                if ((enableCrawlPath && enabledCityDataWithZip) || fbaShouldRenderCitiesList) {
                    ctx.fetchCompositeJSON.addFetches({
                        citiesCrawlPaths: fetchJSON('/rest/lsc/crawl/cities', getCrawlPathsRequestOption(true)),
                    });
                }

                // In LSC the makes and models come from two different endpoints, but in monolith they come from
                // makesAndModelsCrawlPathsEndpoint together.  Once monolith is retired we should rename that variable
                if (!disableNonCitiesCrawlpath && isMakeUrl) {
                    ctx.fetchCompositeJSON.addFetches({
                        modelCrawlPaths: fetchJSON('/rest/lsc/crawl/models', crawlPathsRequestOptions),
                    });
                }

                if (!disableNonCitiesCrawlpath && isModelUrl) {
                    // LSC provides trims via the /trims endpoint. Retrieve trims if a model is specified
                    if (crawlPathsRequestOptions.query.modelCode && shouldFetchTrimsData) {

                        ctx.fetchCompositeJSON.addFetches({
                            trimCrawlPaths: fetchJSON('/rest/lsc/crawl/trims', crawlPathsRequestOptions),
                        });
                    }
                }

                if (makeCode) {
                    if (!isFordBrand && !disableNonCitiesCrawlpath) {
                        // if brand is ford, then do not add listing type fetches
                        ctx.fetchCompositeJSON.addFetches({
                            // only request data for crawl path listing type data if there is a make specified
                            listingTypesCrawlPaths: fetchJSON('/rest/lsc/crawl/listingtypes', crawlPathsRequestOptions),
                        });
                    }

                    if (shouldFetchYearsData) {
                        ctx.fetchCompositeJSON.addFetches({
                            // only request data for crawl path year data if there is a model specified
                            yearCrawlPaths: fetchJSON('/rest/lsc/crawl/modelyears', crawlPathsRequestOptions),
                        });
                    }
                }
            }
        }
    };
}
