import React, { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { useAnalytics } from 'reaxl-analytics';
import { PromoCard } from 'reaxl-listing';

import { paymentsDuck } from '@/ducks';

import { srpActiveInteractionDuck, srpFiltersDuck } from '@/ducks/srp';

import MyWalletBudgetPromoCard from './MyWalletBudgetPromoCard';

const baseIconUrl = 'https://www.autotrader.com/content/static/img/icon';

const placementValues = {
    tradeIn: {
        header: "What's Your Trade-In Worth?",
        buttonText: 'Get My Trade-In Value',
        subText: 'Get your Kelley Blue Book® Trade-In Value and apply it to your next vehicle purchase.',
        imgSrc: `${baseIconUrl}/logos/kbb-logo-text.svg`,
        imgHeight: '72',
        imgWidth: '51',
        panelName: 'kbbValuation',
    },
    payoffQuote: {
        header: 'Know What You Owe',
        buttonText: 'Get My Payoff Quote',
        subText: 'Avoid trade-in delays. Receive a real-time payoff quote from your lender online.',
        imgSrc: `${baseIconUrl}/myWallet/grid/vehicle_listing.svg`,
        imgHeight: '52',
        panelName: 'payoffQuote',
    },
    creditScore: {
        header: 'Your Credit Score Matters',
        buttonText: 'Verify My Credit',
        subText: 'Shop with confidence and get more accurate payment estimates with no impact to your credit score.',
        imgSrc: `${baseIconUrl}/myWallet/grid/credit_&_term.svg`,
        imgHeight: '52',
        panelName: 'creditScore',
    },
    leaseOption: {
        header: 'Consider Leasing for a Lower Payment',
        buttonText: 'Shop Cars for Lease',
        subText: 'Maximize your budget with a lower monthly and cost-effective way to get into a new vehicle.',
        imgSrc: `${baseIconUrl}/myWallet/grid/affordable_new_cars.svg`,
        imgHeight: '52',
        panelName: '',
        placementLabel: 'affordableNewCars',
    },
};

const MyWalletPlacementContainer = memo(({ changePlacement }) => {

    const device = useDevice();

    const isGreaterThanXs = _get(device, 'greaterThan.xs', false);
    const isLessThanLg = _get(device, 'lessThan.lg', false);

    const dispatch = useDispatch();
    const { sendClick } = useAnalytics();

    const filterValueLabels = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips);

    const isNewCarFilterSelected = filterValueLabels.find((filter) => filter.value === 'NEW');

    const budget = useSelector(paymentsDuck.selectors.getBudget);

    const isPaymentsInfoLoaded = useSelector(paymentsDuck.selectors.getIsPaymentsInfoLoaded);
    const paymentOption = useSelector(paymentsDuck.selectors.getPaymentOption);

    const { value: tradeInValue, amountOwed } = useSelector(paymentsDuck.selectors.getTradeIn) || {};
    const lastPlacementInteraction = useSelector(srpActiveInteractionDuck.selectors.getLastPlacementInteraction);

    const [displayValues, setDisplayValues] = useState(placementValues.tradeIn);

    const { header, buttonText, subText, imgSrc, imgHeight, imgWidth, panelName, placementLabel } = displayValues;

    const determineDisplayValues = () => {
        if (paymentOption === 'finance' && isNewCarFilterSelected) {
            setDisplayValues(placementValues.leaseOption);
        } else if (!tradeInValue && ((lastPlacementInteraction !== 'kbbValuation' && lastPlacementInteraction !== 'payoffQuote'))) {
            setDisplayValues(placementValues.tradeIn);
        } else if ((!amountOwed) && ((lastPlacementInteraction !== 'payoffQuote') || lastPlacementInteraction === 'kbbValuation')) {
            setDisplayValues(placementValues.payoffQuote);
        } else if ((tradeInValue && amountOwed) || (lastPlacementInteraction === 'payoffQuote')) {
            setDisplayValues(placementValues.creditScore);
        }

        return {};
    };

    // update display values once payments has been hydrated
    useEffect(() => {
        if (isPaymentsInfoLoaded) {
            determineDisplayValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentsInfoLoaded]);

    // update placements based on last interaction and wallet values when they update
    useEffect(() => {
        if (changePlacement) {
            determineDisplayValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountOwed, changePlacement, lastPlacementInteraction, tradeInValue, paymentOption, isNewCarFilterSelected]);

    const openWalletModal = useCallback(() => {
        dispatch(paymentsDuck.creators.updateMyWallet({
            displayModal: true,
            selectedPanelName: panelName,
        }));
    }, [dispatch, panelName]);

    const handleButtonClick = useCallback((e) => {
        // tracking separate interaction value for lease to keep it outside of wallet based placements rotation
        if (placementLabel !== 'affordableNewCars') {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                lastPlacementInteraction: panelName,
                hasClickedLeasePlacement: false,
            }));
        }

        if (placementLabel === 'affordableNewCars') {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                hasClickedLeasePlacement: true,
            }));
        }

        openWalletModal();

        sendClick('myWalletSrpPlacementClick', e);

    }, [placementLabel, sendClick, dispatch, panelName, openWalletModal]);

    const addButtonStyle = !(isLessThanLg && isGreaterThanXs);
    const buttonStyle = {
        ...(addButtonStyle ? { width: '90%' } : {}),
    };

    if (budget === null) {
        return <MyWalletBudgetPromoCard />;
    }

    return (
        <PromoCard
            data-cmp="myWalletPlacement"
            bodyClassName="text-center padding-top-sm-6 padding-top-3 padding-horizontal-2"
            buttonText={buttonText}
            handleButtonClick={handleButtonClick}
            heading={header}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            imgSrc={imgSrc}
            subText={subText}
            buttonStyle={buttonStyle}
        />
    );
});

export default MyWalletPlacementContainer;
