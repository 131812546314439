import React, { useCallback, useEffect } from 'react';

import { connect } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

// bonnet
import { mapDispatchToActionProp } from '@atc/modular-redux';

import {
    Select,
} from 'reaxl';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
} from '@/ducks/srp';

function CompareTrimFuelTypeDropdown({
    actions: {
        setActiveInteraction = () => { },
    },
    defaultFuelType = '',
    fuelTypeOptions = [],
    fuelTypeGroupFilterOptions,
    fuelTypeGroupFilterValues,
}) {
    const device = useDevice();
    const lessThanMd = _get(device, 'lessThan.md', false);

    const changeFuelType = useCallback(
        (fuelType) => {
            setActiveInteraction({
                compareTrimsFuelType: fuelType,
            });
        },
        [setActiveInteraction],
    );

    const onSelectFuelType = (event) => {
        const fuelType = _get(event, 'target.value', '');
        changeFuelType(fuelType);
    };

    useEffect(() => () => {
        changeFuelType('');
    }, [changeFuelType]);

    useEffect(() => {
        if (fuelTypeGroupFilterValues.length === 1) {
            const value = fuelTypeGroupFilterValues[0];
            const selectedOption = fuelTypeGroupFilterOptions.find((option) => option.value === value);
            let newFuelType = selectedOption.label === 'Gasoline' ? 'Gas' : selectedOption.label;

            if (!fuelTypeOptions.find((trim) => trim.value === newFuelType)) {
                newFuelType = '';
            }

            setActiveInteraction({
                compareTrimsFuelType: newFuelType,
            });
        }
    }, [fuelTypeGroupFilterOptions, fuelTypeGroupFilterValues, fuelTypeOptions, setActiveInteraction]);

    const extendFuelTypeOptions = [
        { label: 'All Fuel Types', value: '' },
        ...fuelTypeOptions,
    ];

    return (
        <Select
            id="fuel-type-dropdown"
            title="Select Fuel Type"
            label="Fuel Type"
            value={defaultFuelType}
            onChange={onSelectFuelType}
            options={extendFuelTypeOptions}
            layout="elementOnly"
            style={{
                width: lessThanMd ? '140px' : '150px',
            }}
        />
    );
}

//  Static util functions for passing state/actions with Redux
const mapStateToProps = (state) => {
    const fuelTypeOptions = srpActiveInteractionDuck.selectors.getFuelTypeOptions(state);
    const defaultFuelType = srpActiveInteractionDuck.selectors.getCompareTrimsFuelType(state);
    const filtersValues = srpFiltersDuck.selectors.getFiltersValues(state);
    const filterOptions = srpFiltersDuck.selectors.getFiltersOptions(state);

    return {
        fuelTypeOptions,
        defaultFuelType,
        fuelTypeGroupFilterValues: _get(filtersValues, 'fuelTypeGroup', []),
        fuelTypeGroupFilterOptions: _get(filterOptions, 'fuelTypeGroup.options', []),
    };
};

//  Static util functions for passing state/actions with Redux
const mapDispatchToProps = mapDispatchToActionProp({
    setActiveInteraction: srpActiveInteractionDuck.creators.setKeys,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareTrimFuelTypeDropdown);
