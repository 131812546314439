import { brands } from 'reaxl-brand';

export default function withCampaignCookie(config) {
    return async (ctx) => {
        const { query, cookies } = ctx;
        const { data } = config;
        const { brand } = ctx.data;

        const campaignKey = brand === brands.KBB_BRAND ? 'psidCode' : 'lnxCode';
        const campaignParam = brand === brands.KBB_BRAND ? 'psid' : 'LNX';
        const brandValue = brand.toUpperCase();

        const { channel } = data.find((code) => query[campaignParam] && query[campaignParam] === code[campaignKey]) ?? {};
        const campaign = channel || cookies.get('CAMP360Cookie');

        if (campaign) {
            ctx.data.channel = [brandValue, campaign];
            cookies.set('CAMP360Cookie', campaign, { maxAge: 604800000 }); // maxAge is in milliseconds (7 days)
        }
    };
}
