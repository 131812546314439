import { fetchJSON } from '@bonnet/core/fetch';

const hasMultipleSelections = (queryObject) => Array.isArray(queryObject) && queryObject.length > 1;

export default function withSEOContent() {
    return async (ctx) => {
        const srpSelfServiceSeoContent = ctx.srpSelfServiceSeo?.content;

        if (typeof window === 'undefined' && srpSelfServiceSeoContent
            && (ctx.srpSelfServiceSeo.withLocation || ctx.match.isNational)) {
            ctx.data.editorialContent = srpSelfServiceSeoContent['tabbed-content'];

            const { makeCodeList } = ctx.query;
            const hasSingleMake = !!makeCodeList && !hasMultipleSelections(makeCodeList);
            if (hasSingleMake) {
                const makeSummary = await fetchJSON('http://localhost:3000/axl-config', {
                    ...ctx.data.requestOptions.optionalRequest,
                    query: { source: 'makeSummary' },
                    circuitBreaker: {
                        ...ctx.data.requestOptions.optionalRequest.circuitBreaker,
                        id: 'axl-config',
                    },
                });

                if (makeSummary && makeSummary[makeCodeList]) {
                    ctx.data.makeSummaryData = makeSummary[makeCodeList];
                }
            }
        } else {
            ctx.data.editorialContent = [];
            ctx.data.makeSummaryData = {};
        }
    };
}
