import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { Chip } from 'reaxl';
import { useFeatures } from 'reaxl-features';

// axl

// atc
import { srpSponsorshipDuck } from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const renderSponsorshipChip = ({ handleSponsorshipClick }) => (
    <Chip
        className="margin-bottom-3 margin-top-1 text-size-400 text-bold btn-primary"
        data-cmp="sponsorshipChip"
        label="Viewing Penske Inventory"
        onClick={handleSponsorshipClick}
    />
);

/*
 * Container for the filter selections that the user will be able to remove one at a time
 */
export function SearchResultsChipsContainer({
    actions,
    isSponsorshipSelected,
}) {
    const navigateToSrp = useSrpNavigation();

    const { setSponsorshipFalse } = actions;
    const {
        SPONSORSHIP_RESULTS_ONLY: [isSponsorshipActive],
    } = useFeatures(['SPONSORSHIP_RESULTS_ONLY']);

    const handleSponsorshipClick = useCallback(() => {
        setSponsorshipFalse();
        // push new instance of srp to history
        navigateToSrp();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSponsorshipFalse]);

    if (!isSponsorshipActive || !isSponsorshipSelected) {
        return null;
    }

    return renderSponsorshipChip({ handleSponsorshipClick });
}

const mapStateToProps = (state) => {
    const sponsorship = srpSponsorshipDuck.selectors.getDuckState(state);

    return {
        isSponsorshipSelected: !!sponsorship,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    setSponsorshipFalse: srpSponsorshipDuck.creators.setFalse,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsChipsContainer);
