export function transformSearch({
    search,
    transformKeys,
    arrayConversions,
}) {
    const transformedKeys = Object.keys(transformKeys);
    const transformedSearch = {};
    const buildArray = (key) => search[key] && search[key].split(',');
    Object.keys(search).forEach((key) => {
        if (transformedKeys.includes(key)) {
            transformedSearch[transformKeys[key]] = buildArray(key);
        } else if (arrayConversions.includes(key)) {
            transformedSearch[key] = buildArray(key);
        } else {
            transformedSearch[key] = search[key];
        }
    });
    return transformedSearch;
}
export default {
    transformSearch,
};
