import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Image } from 'reaxl';
import { getAndSaveConsumerMetadata } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { EmailSubscribeForm } from 'reaxl-molecules';

import { subscribe } from 'axl-config';

import { savedInventoryDuck, userDuck } from '@/ducks';

const saveSearchImage = '/content/static/img/icon/myatc/save-search-illustration.png';

export function EmailSubscribeAlertContainer({ handleSaveSearch = () => { } }) {
    const dispatch = useDispatch();
    const {
        authentication: [, { sign_in_path: signInPath }],
        brand: [, { consumer_brand: consumerBrand }],
        srp_email_subcribe: [enableEmmailSubcribe],
    } = useFeatures([
        'authentication',
        'brand',
        'srp_email_subcribe',
    ]);

    const user = useSelector(userDuck.selectors.getDuckState);
    const buttonState = useSelector(savedInventoryDuck.selectors.getAnimatedButtonState);

    if (enableEmmailSubcribe) {

        const handleSubmit = async (event) => {
            const savedObject = await handleSaveSearch(event);
            dispatch(userDuck.creators.registerEmailSubscribeUser({
                data: {
                    email: event.emailAddress,
                    search: savedObject,
                    keepButtonState: true,
                },
            }));
            getAndSaveConsumerMetadata({
                email: event.emailAddress,
                brand: consumerBrand,
                shouldUpdateConsumerMetadata: true,
            });
        };

        const titleComponent = (
            <>
                <Image
                    src={saveSearchImage}
                    className="margin-right-4 margin-bottom-3"
                    alt=""
                    width="76px"
                    height="55px"
                />
                <div className="text-size-400">Receive Price Alerts and New Results for this Search!</div>
            </>
        );

        const navigateToSignIn = () => {
            const refUrl = new URL(globalThis.location.href);
            refUrl.pathname = signInPath;
            refUrl.search = new URLSearchParams({
                defaultScreen: 'signup',
                refUrl,
            });
            globalThis.location.assign(refUrl);
        };

        const signUpComponent = user.showEmailSubscribeFailure ? (
            <>
                <span className="text-bold">You have a maximum of 2 searches saved.</span>
                {' '}
                <Button
                    className="margin-right-1"
                    onClick={navigateToSignIn}
                    bsStyle="text"
                >
                    Create a free account
                </Button>
                {subscribe.inline.confirmation.signUp}
            </>
        )
            : null;

        const emailSubscribeSend = {
            buttonText: 'Subscribe',
            loadedText: 'Subscribed!',
            buttonState,
        };

        const onEmailInputChange = () => {
            if (buttonState === 'loaded') {
                dispatch({
                    type: savedInventoryDuck.types.SET_ANIMATED_BUTTON_STATE,
                    payload: '',
                });
            }
        };

        return (
            <EmailSubscribeForm
                data-cmp="cntnr-eml-capture"
                uiContext="light"
                user={user}
                onSubmit={handleSubmit}
                emailSubscribeSend={emailSubscribeSend}
                isSubscribeAlert
                title={titleComponent}
                registrationFailure={signUpComponent}
                registrationError={user?.emailRegistrationError}
                onEmailInputChange={onEmailInputChange}
            />
        );
    }
    return null;
}

export default React.memo(EmailSubscribeAlertContainer);
