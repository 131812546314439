import React from 'react';

import {
    Glyphicon,
} from 'reaxl';

// functions
const checkmarkGlyph = (
    <Glyphicon
        className="text-success"
        glyph="success"
    />
);

const unavailableGlyph = (
    <Glyphicon
        className="text-gray-muted"
        glyph="close"
    />
);

const unavailableInfo = (
    <span className="text-subdued-lightest">Information Unavailable</span>
);

const renderSpecAvailability = (activeSpecs, specId) => {
    if (!activeSpecs) {
        return unavailableInfo;
    }
    const activeSpecItems = activeSpecs?.items || [];
    const activeSpec = activeSpecItems.filter((spec) => spec?.specId === specId);
    const availability = activeSpec?.[0]?.specValue;

    switch (availability) {
        case 'Standard':
            return checkmarkGlyph;
        case 'Not Available':
            return unavailableGlyph;
        case 'Optional':
            return 'Available';
        default:
            return unavailableInfo;
    }
};

const addSpecAttribute = (activeSpecs) => {
    const heatedSeatsData = renderSpecAvailability(activeSpecs, 546);
    const leatherSeatsData = renderSpecAvailability(activeSpecs, 561);
    const rearviewCameraData = renderSpecAvailability(activeSpecs, 610);
    const bluetoothData = renderSpecAvailability(activeSpecs, 170);
    const alarmSystemData = renderSpecAvailability(activeSpecs, 160);
    const laneDepartureData = renderSpecAvailability(activeSpecs, 557);
    const navigationData = renderSpecAvailability(activeSpecs, 236);
    const remoteStartData = renderSpecAvailability(activeSpecs, 615);
    const sunroofData = renderSpecAvailability(activeSpecs, 578);

    const specRow = {
        heatedSeatsData,
        leatherSeatsData,
        rearviewCameraData,
        bluetoothData,
        alarmSystemData,
        laneDepartureData,
        navigationData,
        remoteStartData,
        sunroofData,
    };

    return specRow;
};

export default addSpecAttribute;
