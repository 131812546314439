import React, { memo, useCallback } from 'react';

import { connect } from 'react-redux';

// bonnet
import { mapDispatchToActionProp } from '@atc/modular-redux';

import { useFeatures } from 'reaxl-features';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterMobileControlsContainer from './FilterMobileControlsContainer';

function FilteredResultsHeaderContainerComponent({
    actions,
    renderCompareToggle,
    className,
    isStickyHeader,
}) {
    const {
        RSRP_FIXED_HEADER_OFF: [rsrpFixedHeaderDisabled] = false,
    } = useFeatures(['RSRP_FIXED_HEADER_OFF']);

    const {
        showFilters,
    } = actions;

    const handleFilterClick = useCallback(() => {
        showFilters();
    }, [showFilters]);

    return !rsrpFixedHeaderDisabled && (
        <div className="row">
            <FilterMobileControlsContainer
                className={className}
                onFilterClick={handleFilterClick}
                renderCompareToggle={renderCompareToggle}
                isStickyHeader={isStickyHeader}
            />
        </div>
    );
}

export const FilteredResultsHeaderContainer = memo(FilteredResultsHeaderContainerComponent);

const mapDispatchToProps = mapDispatchToActionProp({
    showFilters: srpFiltersDuck.creators.showFilters,
});

export default connect(null, mapDispatchToProps)(FilteredResultsHeaderContainer);
