import { fetchJSON } from '@bonnet/next/fetch';

export default ({ makeCode, modelCode, startYear }) => {
    const safetyData = fetchJSON('/rest/lsc/modelinfo/ymm?', {
        headers: {
            'Content-Type': 'application/json',
        },
        circuitBreaker: {
            id: 'ymmModelInfo',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for /rest/lsc/modelinfo/ymm is open',
            },
        },
        query: {
            makeCode,
            modelCode,
            startYear,
            numRecords: 1,
        },
    });

    return safetyData || {};
};
