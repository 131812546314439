import _get from 'lodash/get';

import { brands } from 'reaxl-brand';

import { getCertifiedTileByProductId } from 'axl-config';

import vdpUrlBuilder from './vdpUrlBuilder';

// helper methods
const getAltValueFromTitle = (cpoProductId = '', title = '', make = '') => {
    if (cpoProductId && cpoProductId.toLowerCase().includes(brands.FORD_BRAND)) {
        return `${make} ${title}`;
    }
    return title;
};

/*
 * mutate the listing object to have desired tile state
 */
const configureListingTiles = ({
    brand,
    certifiedSponsor,
    items,
    isOverrideCarfaxTileEnabled,
    partner,
    tier,
    removeCarfaxTile,
}) => items.map((listing) => {

    const {
        productTiles = [],
        owner = {},
        vin,
        makeCode,
        vdpBaseUrl,
    } = listing;

    const certifiedProduct = _get(listing, 'certifiedProduct', undefined);
    const make = _get(listing, 'make.name', '');

    const cpoProduct = getCertifiedTileByProductId(certifiedProduct, makeCode, certifiedSponsor);

    // SEO: If the cpo tile has an image with no alt attribute, set the value to the title or the label instead
    const { title = '', cpoTile = '', productId = '', label = '' } = cpoProduct;

    let altValue = '';
    if (cpoTile) {
        altValue = getAltValueFromTitle(productId, title, make) || `${make} Certified Pre-Owned logo`;
    }

    const certifiedTile = [{
        cmp: 'ec_pa_lgo',
        cprd: `${makeCode}_CERT`,
        epn: 'CERT',
        image: {
            alt: altValue,
            src: cpoTile,
        },
        link: {
            href: vdpUrlBuilder({
                baseUrl: vdpBaseUrl,
                clickType: tier,
                tileClick: 'true',
                anchor: 'warrantySection',
            }),
            label: `${make} Certified Pre-Owned`,
            offsite: false,
            partner: false,
            target: '_self',
        },
        tileType: 'CERTIFIED_CAR',
        label,
    }];
    const carfaxTile = productTiles.filter((tile) => tile.tileType.startsWith('CARFAX'));
    const carfaxTileImage = _get(carfaxTile, '[0].image.src', '/content/web/images/na/partner-tiles/255821.gif');
    // For certified spotlights, we need to remove the Certified tiles from productTiles so they aren't
    // duplicated. LSC puts another tile in but we can't remove it from LSC since the apps need it
    let filteredTiles = productTiles.filter((tile) => tile.tileType !== 'CERTIFIED_CAR');
    if (isOverrideCarfaxTileEnabled || removeCarfaxTile) {
        filteredTiles = filteredTiles.filter((tile) => !tile.tileType.startsWith('CARFAX'));
    }
    if (isOverrideCarfaxTileEnabled) {
        const whitelabelCarfaxTile = {
            cmp: 'ec_pa_lgo',
            cprd: 'View_CERT',
            epn: 'carfaxVHR',
            image: {
                alt: `View the Free CARFAX Report for this ${make}`,
                src: carfaxTileImage,
            },
            link: {
                href: `https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=${partner}&vin=${vin}`,
                label: `View the Free CARFAX Report for this ${make}`,
                offsite: true,
                partner: true,
            },
            tileType: 'CARFAX_APPENDED',
        };
        filteredTiles.push(whitelabelCarfaxTile);
    }
    return {
        ...listing,
        owner,
        certifiedTiles: certifiedTile,
        productTiles: filteredTiles.map((tile) => {
            let updatedHref = tile.link.href;
            updatedHref = tile.tileType === 'EXPERIAN_APPENDED' ? updatedHref + `&brand=${brand}` : updatedHref;

            // SEO: If the tile has an image with no alt attribute set the value to the title instead
            const { src = '', alt = '', title: tileTitle = '' } = tile?.image || {};
            if (src && !alt) {
                tile.image.alt = tileTitle;
            }

            let productLabel = '';
            const tileType = tile?.tileType || '';
            const subType = tile?.tileSubType || '';

            if (tileType.includes('CARFAX')) {
                if (subType === 'ONE_OWNER') {
                    productLabel = 'CARFAX 1 OWNER';
                } else {
                    productLabel = 'CARFAX';
                }
            } else if (tileType.includes('EXPERIAN')) {
                if (subType === 'ONE_OWNER') {
                    productLabel = 'AUTOCHECK 1 OWNER';
                } else if (subType === 'BUY_BACK') {
                    productLabel = 'AUTOCHECK BUYBACK PROTECTION';
                } else {
                    productLabel = 'AUTOCHECK';
                }
            }

            return {
                ...tile,
                linkVisible: !src,
                label: productLabel,
                link: {
                    ...tile.link,
                    href: updatedHref,
                },
            };
        }),
    };
});

export default configureListingTiles;
