import { DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { srpDuckPreset } from '@/ducks/presets';

export default resultsDuckCreator({
    ...srpDuckPreset,
    store: 'similarlistings',
}).extend({
    selectors: () => ({
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
    }),
});
