import React, { memo, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import _cloneDeep from 'lodash/cloneDeep';

import { getPathSRP } from '@/utilities';

import { Link, ListColumns } from 'reaxl';

import {
    getPricingModelList,
    getPricingTrimList,
    getYearBasedPricingDetails,
} from '@/utilities/getPricingSEOData';

import { queryDuck } from '@/ducks';

import {
    srpCrawlPathsDataMapsDuck,
} from '@/ducks/srp';

function PricingSEOContentContainer({ modelYearList, trimList }) {
    const [preFilteredSRPLink, setPreFilteredSRPLink] = useState('');

    const queryList = useSelector(queryDuck.selectors.getDuckState);
    const { makeCode, modelCode, startYear, endYear } = queryList;

    const { makesMap, modelsMap } = useSelector(srpCrawlPathsDataMapsDuck.selectors.getDuckState);
    const makeKnown = queryList?.makeCode && !Array.isArray(queryList?.makeCode);
    const modelKnown = queryList?.modelCode && !Array.isArray(queryList?.modelCode);
    const trimKnown = queryList?.trimCode && !Array.isArray(queryList?.trimCode);
    const trimName = trimKnown ? queryList?.trimCode?.split('|')?.[1] : '';

    const makeName = makeKnown && makesMap ? makesMap[queryList?.makeCode.toUpperCase()] : '';
    const modelName = modelKnown && modelsMap ? modelsMap[queryList?.modelCode] : '';

    useEffect(() => {
        if (!trimName) {
            const filteredHref = getPathSRP({
                dealType: 'greatprice',
                listingType: 'USED',
                startYear,
                endYear,
                makeCode,
                modelCode,
            });
            filteredHref.then((href) => {
                setPreFilteredSRPLink(href);
            });
        }
    }, [makeName, modelName, trimName, startYear, endYear, makeCode, modelCode]);

    const modelYearsData = useMemo(() => _cloneDeep(modelYearList), [modelYearList]);
    const trimsData = useMemo(() => _cloneDeep(trimList), [trimList]);
    const pricingModelList = getPricingModelList(modelYearsData, makeName, modelName, trimName);
    const pricingTrimList = getPricingTrimList(trimsData, makeName, modelName);
    const yearPriceData = getYearBasedPricingDetails(modelYearsData, startYear);
    const yearLabel = startYear || '';
    const kbbRatedPriceLink = `Find a ${yearLabel} ${makeName} ${modelName} Rated with a KBB great price`;
    const pricingYearHeaderBoldTxt = `${startYear} ${makeName} ${modelName} Price ${trimName ? 'by Trim' : ''} - `;
    const pricingYearHeaderTxt = `Prices are based on actual listings found on Autotrader ${trimName ? 'for each trim.' : 'across all trims.'}`;
    const headerBoldTxt = startYear ? pricingYearHeaderBoldTxt : `Used ${makeName} ${modelName} ${trimName} Price By Year - `;
    const headerTxt = startYear ? pricingYearHeaderTxt : `Prices are based on actual listings found on Autotrader for the model years ${pricingModelList?.yearRange}.`;

    const preFilterSRPLink = (
        <Link
            title="KBB great price"
            href={preFilteredSRPLink}
            label={kbbRatedPriceLink}
        />
    );

    const renderYearBasedPricing = () => (
        <ListColumns
            className="list-bulleted padding-top-3 text-size-400"
            items={[
                `Low Price: ${yearPriceData?.formattedMinPrice}`,
                `Average Price: ${yearPriceData?.formattedAvgPrice}`,
                `Max Price: ${yearPriceData?.formattedMaxPrice}`,
            ]}
        />
    );

    const getModelListItems = () => pricingModelList?.links?.map((item) => {
        const avgPriceText = ` -  ${item.formattedAvgPrice} Average Price`;
        return (
            <>
                <Link
                    className="text-bold"
                    href={item.link}
                    title={item.value}
                >
                    {`${item.value} ${item.makeName} ${item.modelName} ${item.trimName}`}
                </Link>
                {avgPriceText}
            </>
        );
    });

    const getTrimListItems = () => pricingTrimList?.map((item) => {
        const avgPriceText = `:  ${item.formattedAvgPrice} Average Price`;
        const priceLink = `${startYear} ${item.makeName + ' ' + item.modelName + ' ' + item.trimCode}`;
        return (
            <>
                <Link
                    className="text-bold"
                    href={item.link}
                    title={item.value}
                >
                    {priceLink}
                </Link>
                {avgPriceText}
            </>
        );
    });

    const renderPricingList = () => {
        const pricingListItems = (trimName && startYear) ? getTrimListItems() : getModelListItems();

        return (
            (startYear && yearPriceData && !trimName) ? renderYearBasedPricing() : (
                <ListColumns
                    className="list-bulleted padding-top-3 text-size-300"
                    items={pricingListItems}
                />
            )
        );
    };

    const renderHeadline = (
        <>
            <span className="text-subdued-lighter text-bold text-size-400">
                {headerBoldTxt}
            </span>
            <span className="text-subdued-lighter text-bold text-size-300">
                {headerTxt}
            </span>
        </>
    );

    return (
        <div className="padding-top-4">
            {renderHeadline}
            {renderPricingList()}
            {!trimName && preFilterSRPLink}
        </div>
    );
}

export default memo(PricingSEOContentContainer);
