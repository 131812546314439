import React, { memo, useEffect } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { pageNames } from '@atc/bonnet-paths';
import { mapDispatchToActionProp } from '@atc/modular-redux';

//  Ads
import { getPixallId } from 'atc-js';

import { LazyRender } from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import { Page as PageSchema } from 'reaxl-schema';

import usePaidSearchCookies from '@/utilities/usePaidSearchCookies';

import remoteActions from '@/actions/srp/remoteActions';

import { srpActiveInteractionDuck, srpAdsDuck, srpFiltersDuck } from '@/ducks/srp';

import AdsProviderContainer from '@/containers/AdsProviderContainer';
import ConsumerRatingsContainer from '@/containers/ConsumerRatingsContainer';
import MyWalletContainer from '@/containers/MyWalletContainer';

import FilterAccordionsPlaceholder from '@/components/filters/FilterAccordionsPlaceholder';
import LazyComponent from '@/components/LazyComponent';

import AlphaShowcaseContainer from './AlphaShowcaseContainer';
import AnalyticsWrapper from './AnalyticsWrapper';
import EditorialContentContainer from './EditorialContentContainer';
import EmailSubscribeContainer from './EmailSubscribeContainer';
import ExpiredListingRedirectAlertMessageContainer from './ExpiredListingRedirectAlertMessageContainer';
import FixedAdContainer from './FixedAdContainer';
import FixedAdWaypointContainer from './FixedAdWaypointContainer';
import FooterLeaderAdSlotContainer from './FooterLeaderAdSlotContainer';
import InventorySearchResultsContainer from './InventorySearchResultsContainer';
import ModelReferenceContainer from './ModelReferenceContainer';
import SafetyReferenceContainer from './SafetyReferenceContainer';
import SearchResultsChipContainer from './SearchResultsChipsContainer';
import SearchResultsPageTopActionsContainer from './SearchResultsPageTopActionsContainer';
import SearchResultsRecentSearchesContainer from './SearchResultsRecentSearchesContainer';
import SearchResultsTitleContainer from './SearchResultsTitleContainer';

// lazy load containers
const SRLTowerAdSlotContainer = dynamic(() => import(
    /* webpackChunkName: "SRLTowerAdSlotContainer" */
    './SRLTowerAdSlotContainer'
), { ssr: false });
const CarLoader = dynamic(() => import(
    /* webpackChunkName: "CarLoader" */
    'reaxl/lib/CarLoader'
), { ssr: false });
const AlternativeSearchAction = dynamic(() => import(
    /* webpackChunkName: "AlternativeSearchAction" */
    '@/components/AlternativeSearchAction'
), { ssr: false });
const CompareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "CompareListingsContainer" */
    '@/containers/CompareListingsContainer'
), { ssr: false });
const ReportAtcListing = dynamic(() => import(
    /* webpackChunkName: "ReportAtcListing" */
    '@/components/ReportAtcListing/index.js'
), { ssr: false });
const ShareListingsContainer = dynamic(() => import(
    /* webpackChunkName: "ShareListingsContainer" */
    '@/containers/ShareListingsContainer'
), { ssr: false });

// lazy load SRP containers
const CrawlPathFooterContainer = dynamic(() => import(
    /* webpackChunkName: "CrawlPathFooterContainer" */
    '@/containers/srp/CrawlPathFooterContainer'
), { ssr: false });
const InventoryFiltersContainer = dynamic(() => import(
    /* webpackChunkName: "InventoryFiltersContainer" */
    '@/containers/srp/InventoryFiltersContainer'
), {
    loading: () => (<FilterAccordionsPlaceholder />),
    ssr: false,
});
const InventoryPaginationContainer = dynamic(() => import(
    /* webpackChunkName: "InventoryPaginationContainer" */
    '@/containers/srp/InventoryPaginationContainer'
), { ssr: false });
const SaveSearchContainer = dynamic(() => import(
    /* webpackChunkName: "SaveSearchContainer" */
    '@/containers/srp/SaveSearchContainer'
), { ssr: false });
const ShareListingContainer = dynamic(() => import(
    /* webpackChunkName: "ShareListingContainer" */
    '@/containers/srp/ShareListingContainer'
), { ssr: false });
const SRPBuyerConnectContainer = dynamic(() => import(
    /* webpackChunkName: "SRPBuyerConnectContainer" */
    '@/containers/srp/SRPBuyerConnectContainer'
), { ssr: false });
const SearchResultsEmailModalContainer = dynamic(() => import(
    /* webpackChunkName: "SearchResultsEmailModalContainer" */
    '@/containers/srp/SearchResultsEmailModalContainer'
), { ssr: false });
const SRPHeaderContainer = dynamic(() => import(
    /* webpackChunkName: "SRPHeaderContainer" */
    '@/containers/srp/SRPHeaderContainer'
), { ssr: false });

// page name constants
const { SEARCH_RESULTS } = pageNames;

// functions
const alphaLeaderBoardContainerOnPositionChange = (handlerProps) => {

    const { actions } = handlerProps;

    if ((handlerProps.currentPosition === 'inside'
        || handlerProps.currentPosition === 'above')
        && handlerProps.previousPosition === undefined) {
        actions.hideFixedAd();
    }
    if (handlerProps.currentPosition === 'inside' && handlerProps.previousPosition === 'below') {
        actions.hideFixedAd();
    }
    if (handlerProps.currentPosition === 'below' && handlerProps.previousPosition === 'inside') {
        actions.showFixedAd();
    }
};

const fixedAdWayPointContainerOnPositionChange = (handlerProps) => {

    const { actions } = handlerProps;

    if (handlerProps.currentPosition === 'above' && handlerProps.previousPosition === undefined) {
        actions.showFixedAd();
    } else if (handlerProps.currentPosition === 'inside' && handlerProps.previousPosition === 'above') {
        actions.showFixedAd();
        setTimeout(() => {
            actions.refreshFixedAd('top');
        }, 1);
    }
};

const showFixedAd = (handlerProps) => {
    handlerProps.actions.showFixedAd();
};

const hideFixedAd = (handlerProps) => {
    handlerProps.actions.hideFixedAd();
};

function fixedAdWayPointContainer() {
    return (
        <FixedAdWaypointContainer
            cmp="leaderboard"
            onEnter={showFixedAd}
            onPositionChange={fixedAdWayPointContainerOnPositionChange}
        />
    );
}

const searchResultsTitleContainer = (isKbbBranded, enableNewRecentSearchesPlacement) => {
    const rootClassNames = clsx('container padding-0 margin-vertical-3', {
        'padding-top-2': isKbbBranded,
    });
    return (
        <div className={rootClassNames}>
            {!enableNewRecentSearchesPlacement && <SearchResultsRecentSearchesContainer />}
            <SearchResultsTitleContainer />
        </div>
    );
};

const affixedKbbSRLTowerAdContainerRef = React.createRef();

function SearchResultsPageContainer({ currentUrl, actions }) {
    const {
        alpha_showcase: [isAlphaEnabled],
        brand: [, { channel }],
        compare: [showCompare],
        consumer_ratings: [enableConsumerRatings, { srpRatings: enableSrpRatings }],
        enable_crawlpath_migration: [enableCrawlPathMigration],
        enable_still_interested_view_count: [enableStillInterestedViewCount],
        global_personalization: [isGlobalPersonalizationEnabled],
        my_atc_shareable_link: [enableSharableLink],
        my_wallet: [enableMyWallet],
        organization_schema: [isOrganizationSchemaEnabled],
        schema: [, { page_schema: pageSchema } = {}],
        srp_alpha_leader_board: [enableAlphaLeaderBoard],
        srp_email_subcribe: [enableEmmailSubcribe],
        srp_expert_reviews: [enableExpertReviews],
        srp_fixed_ad_waypoint: [enableFixedAdWaypoint],
        srp_model_reference: [enableModelReference],
        srp_paid_search_cookies: [enablePaidSearchCookies],
        recent_searches_new_placement: [enableNewRecentSearchesPlacement],
    } = useFeatures([
        'alpha_showcase',
        'brand',
        'compare',
        'consumer_ratings',
        'enable_crawlpath_migration',
        'enable_still_interested_view_count',
        'global_personalization',
        'my_atc_shareable_link',
        'my_wallet',
        'nds',
        'organization_schema',
        'schema',
        'srp_alpha_leader_board',
        'srp_email_subcribe',
        'srp_expert_reviews',
        'srp_fixed_ad_waypoint',
        'srp_model_reference',
        'srp_paid_search_cookies',
        'recent_searches_new_placement',
    ]);
    const { brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const device = useDevice();
    const dispatch = useDispatch();

    const showCompareModal = useSelector(srpActiveInteractionDuck.selectors.getShowCompareModal);
    const showEmailModal = useSelector(srpActiveInteractionDuck.selectors.getShowEmailModal);
    const isShareModalActive = useSelector(srpActiveInteractionDuck.selectors.getShowShareListingModal);
    const isReportListingModalActive = useSelector(srpActiveInteractionDuck.selectors.getShowReportModal);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const showSpinningIndicator = useSelector(srpActiveInteractionDuck.selectors.getShowSpinningIndicator);

    const isFiltersCollapsed = !!_get(device, 'lessThan.md', false);

    // set kbb paid search cookies
    if (enablePaidSearchCookies) {
        // react hook rules reserve "use" prefixes
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePaidSearchCookies();
    }

    const { getConsumerBrowsingHistoryData } = actions;

    useEffect(() => {
        const pixAllId = getPixallId() || 'nl';
        if (pixAllId && pixAllId !== 'nl') {
            getConsumerBrowsingHistoryData({ channel, pixAllId, enableViewCount: enableStillInterestedViewCount });
        }

        return () => {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                showSpinningIndicator: false,
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // scroll restoration
    useEffect(() => {

        setTimeout(() => {
            window.scrollTo({
                top: localStorage.getItem('scrollY'),
                behavior: 'smooth',
            });
        }, 100);

        return () => {
            // reset y position on tab close
            window.onbeforeunload = () => {
                localStorage.setItem('scrollY', 0);
            };
        };
    }, []);

    const renderModals = () => (
        <>
            {enableMyWallet && showCompare && showCompareModal && (
                <LazyComponent renderOnScroll={false}>
                    <CompareListingsContainer />
                </LazyComponent>
            )}

            {enableSharableLink && (
                <LazyComponent renderOnScroll={false}>
                    <ShareListingsContainer />
                </LazyComponent>
            )}

            {isShareModalActive && (
                <LazyComponent renderOnScroll={false}>
                    <ShareListingContainer />
                </LazyComponent>
            )}

            {isReportListingModalActive && (
                <LazyComponent renderOnScroll={false}>
                    <ReportAtcListing />
                </LazyComponent>
            )}
        </>
    );

    return (
        <>
            {showSpinningIndicator && (
                <div id="page-overlay-container">
                    <CarLoader />
                </div>
            )}
            <LazyComponent
                fallback={null}
                renderOnScroll={false}
            >
                <SRPHeaderContainer
                    currentUrl={currentUrl}
                />
            </LazyComponent>
            {showEmailModal && <SearchResultsEmailModalContainer />}

            {enableMyWallet && (
                <MyWalletContainer
                    blockTradeInFetch
                />
            )}

            <main aria-labelledby="search-results-heading">
                {searchResultsTitleContainer(isKbbBranded, enableNewRecentSearchesPlacement)}

                <div className="container padding-sm-0">
                    <AdsProviderContainer
                        adSelector={srpAdsDuck.selectors.getDuckState}
                        pageName={SEARCH_RESULTS}
                    >
                        <div className="row margin-horizontal-0 padding-horizontal-0">
                            {fixedAdWayPointContainer()}

                            <div
                                className="row display-sm-flex"
                                ref={affixedKbbSRLTowerAdContainerRef}
                            >

                                <div className="col-md-3 display-none display-md-block">
                                    {(!isFiltersCollapsed || isFiltersVisible) && (
                                        <LazyComponent
                                            fallback={<FilterAccordionsPlaceholder />}
                                            renderOnScroll={false}
                                        >
                                            <InventoryFiltersContainer />
                                        </LazyComponent>
                                    )}
                                    <LazyComponent>
                                        <SRLTowerAdSlotContainer affixedRef={affixedKbbSRLTowerAdContainerRef} />
                                    </LazyComponent>
                                </div>

                                <div
                                    className="col-xs-12 col-md-9"
                                    id="srp-listings"
                                >

                                    {(enableEmmailSubcribe || isGlobalPersonalizationEnabled) && <EmailSubscribeContainer />}

                                    <SearchResultsPageTopActionsContainer />

                                    <ExpiredListingRedirectAlertMessageContainer />

                                    <SearchResultsChipContainer />

                                    {isAlphaEnabled && <AlphaShowcaseContainer />}

                                    {isOrganizationSchemaEnabled && <PageSchema pageSchemaData={pageSchema} />}

                                    <InventorySearchResultsContainer />

                                    {renderModals()}

                                    {enableAlphaLeaderBoard && (
                                        <FixedAdWaypointContainer
                                            cmp="alphaLeaderboard"
                                            onPositionChange={alphaLeaderBoardContainerOnPositionChange}
                                        />
                                    )}

                                    {enableExpertReviews && <EditorialContentContainer />}

                                    <LazyComponent>
                                        <AlternativeSearchAction />
                                    </LazyComponent>

                                    <LazyComponent>
                                        <SaveSearchContainer isEmailCapture />
                                    </LazyComponent>

                                    <LazyComponent>
                                        <InventoryPaginationContainer />
                                    </LazyComponent>

                                    <LazyComponent>
                                        <SRPBuyerConnectContainer position="below" />
                                    </LazyComponent>

                                    <FooterLeaderAdSlotContainer />
                                </div>
                            </div>
                        </div>

                        <FixedAdContainer />
                        <AnalyticsWrapper />

                    </AdsProviderContainer>
                    <hr className="margin-top-5" />
                </div>

                {enableModelReference
                    && (
                        <LazyRender
                            height={850}
                            threshold={1000}
                        >
                            <ModelReferenceContainer />
                        </LazyRender>
                    )}

                {(enableConsumerRatings || enableModelReference) && (
                    <LazyRender height={450}>
                        <SafetyReferenceContainer />
                    </LazyRender>
                )}

                {enableConsumerRatings && enableSrpRatings && (
                    <LazyRender
                        height={400}
                    >
                        <ConsumerRatingsContainer />
                    </LazyRender>
                )}

                <div className="container">
                    {enableCrawlPathMigration ? (
                        <LazyRender
                            threshold={10000}
                            height={250}
                        >
                            <CrawlPathFooterContainer />
                        </LazyRender>
                    ) : <CrawlPathFooterContainer />}

                    {enableFixedAdWaypoint && (
                        <FixedAdWaypointContainer
                            cmp="leaderboard"
                            onEnter={hideFixedAd}
                            onLeave={showFixedAd}
                        />
                    )}
                </div>

            </main>
        </>
    );
}

const MemoizedSearchResultsPageContainer = memo(SearchResultsPageContainer);

const mapDispatchToProps = mapDispatchToActionProp({
    getConsumerBrowsingHistoryData: remoteActions.getConsumerBrowsingHistoryData,
});

export default connect(null, mapDispatchToProps)(MemoizedSearchResultsPageContainer);
