const GM_CERT_MAKES = {
    BUICK: 'Buick',
    CAD: 'Cadillac',
    CHEV: 'Chevrolet',
    GMC: 'GMC',
};

export default function getCpoByMake(filterMakeSelected, cpoData) {
    const linkMap = {};

    if (filterMakeSelected && cpoData) {
        for (const key of Object.keys(cpoData)) {
            for (const make of filterMakeSelected) {
                if (make === cpoData[key].makeCode) {
                    linkMap[key] = { ...cpoData[key], productId: key };
                } else if (GM_CERT_MAKES[make]) {
                    linkMap.CARBRAVO_CERT = {
                        ...cpoData.CARBRAVO_CERT,
                        makeLabel: GM_CERT_MAKES[make],
                        productId: 'CARBRAVO_CERT',
                    };
                }
            }
        }
    }

    return linkMap;
}
