import { fetchJSON } from '@bonnet/next/fetch';

export default (value, additionalOptions) => {
    const options = {
        circuitBreaker: {
            id: 'suggestedKeywords',
            timeout: 3000,
            resetTimeout: 30000,
            fallback: {},
        },
        ...additionalOptions,
    };

    return fetchJSON('/rest/lsc/marketplace/suggested/keywords/' + value, options);
};
