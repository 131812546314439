import _isEmpty from 'lodash/isEmpty';

import { getPathSRP } from '.';

const updateWithPath = async ({ data = {}, query = {}, isModelYearData = false }) => {
    const buildPromises = data.map(async (item) => {
        const startYear = isModelYearData ? item?.value : query?.startYear;
        const modelYear = isModelYearData ? item?.value : '';
        const endYear = !_isEmpty(query?.endYear) ? query?.endYear : modelYear;
        const trimCode = !isModelYearData ? item?.value : query?.trimCode;
        const path = await getPathSRP({
            startYear,
            endYear,
            listingType: 'USED',
            makeCode: query?.makeCode,
            modelCode: query?.modelCode,
            trimCode,
        }, {
            basePath: true,
        });
        return {
            ...item,
            link: path,
        };
    });

    return Promise.all(buildPromises);
};

export default async function processPricingPath({
    data = {},
    query = {},
    isModelYearData = false,
}) {
    return updateWithPath({ data, query, isModelYearData });
}
