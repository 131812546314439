import _get from 'lodash/get';

import { getReference } from '@atc/bonnet-reference';

import refPackageFilterFetcher from '@/fetchers/refPackageFilterFetcher';

const includeLSCFilterUpdates = [
    'vehicleStyleCode',
    'driveGroup',
    'transmissionCode',
    'engineCode',
    'fuelTypeGroup',
    'featureCode',
    'doorCode',
    'yearRange',
    'featureCodeSubGroup',
];

const getLSCData = (lscCode, lscData) => _get(lscData, lscCode, {});

const getLSCFilterOption = (lscFilterData) => (lscFilterData && _get(lscFilterData, 'options', [])) || [];

const buildCounts = (lscCode, lscData, group) => {
    const lscPayload = getLSCData(lscCode, lscData);
    const options = _get(lscPayload, 'options', []);
    const filterOption = options && options.find(({ value }) => value === group.code);
    return _get(filterOption, 'count', null);
};

const buildSeriesModelsCounts = (lscCode, lscFilterGroupsData, group, makeCode) => {
    const lscPayload = getLSCData(lscCode, lscFilterGroupsData);
    const makeNode = _get(lscPayload, makeCode, {});
    const options = makeNode && _get(makeNode, 'options', []);
    const filterOption = options && options.find(({ value }) => value === group.code);
    return _get(filterOption, 'count', null);
};

const buildTrimCounts = (lscCode, lscFilterGroupsData, group, modelCode) => {
    const lscFilterGroupPayload = getLSCData(lscCode, lscFilterGroupsData);
    const modelNode = _get(lscFilterGroupPayload, modelCode, {});
    const options = modelNode && _get(modelNode, 'options', []);
    const filterOption = options && options.find(({ value }) => value === `${modelCode}|${group.code}`);
    return filterOption && _get(filterOption, 'count', null);
};

const buildPackageCount = (lscCode, lscFilterGroupsData, trimModelCode, packageValue) => {
    const lscFilterGroupPayload = getLSCData(lscCode, lscFilterGroupsData);
    const packageNode = _get(lscFilterGroupPayload, trimModelCode, {});
    const options = packageNode && _get(packageNode, 'options', []);
    const filterOption = options && options.find(({ value }) => value === packageValue);
    return filterOption && _get(filterOption, 'count', null);
};

export const buildFordValue = (queryList) => {
    const vehicleExchange = [];
    const vehicleExchangeVal = _get(queryList?.moneyBackGuarantee, 'vehicleExchange', false);
    if (vehicleExchangeVal) {
        vehicleExchange.push('true');
    }
    return vehicleExchange;
};

export const buildFordSelected = (queryList) => _get(queryList?.moneyBackGuarantee, 'vehicleExchange', false);

export const buildValue = (selectedFilterValue) => {
    let filterValue = [];
    if (selectedFilterValue !== null && selectedFilterValue !== undefined) {
        if (!Array.isArray(selectedFilterValue)) {
            filterValue.push(selectedFilterValue);
        } else {
            filterValue = selectedFilterValue;
        }
    }
    return filterValue;
};
const isValidCode = (code) => {
    let isValid = false;
    if (code !== null && code !== undefined && typeof code === 'string') {
        isValid = true;
    }
    return isValid;
};

// Persist Selected filter value
const buildSelected = (group, selectedFilterKey) => {
    let filterOption = null;
    if (selectedFilterKey !== null && selectedFilterKey !== undefined) {
        if (Array.isArray(selectedFilterKey)) {
            filterOption = selectedFilterKey && selectedFilterKey.find((element) => element === group);
        } else {
            filterOption = selectedFilterKey && selectedFilterKey === group;
        }
    }
    return filterOption !== null && !!filterOption;
};

export const buildCollapsed = (selectedFilterValue) => {
    let collapsed = true;
    const filterValue = buildValue(selectedFilterValue);
    const hasValue = filterValue !== null && filterValue.length > 0;
    if (hasValue) {
        collapsed = false;
    }
    return collapsed;
};

// Disable filter values for 0 counts except exclude filters
const buildFilterDisabled = (lscCode, filterCount, queryList) => {
    let filterDisabled = false;
    const isNationWideSearch = queryList?.searchRadius === '0';
    const excludedFiltersCount = [
        'searchRadius',
    ];
    if (!isNationWideSearch) {
        if (!excludedFiltersCount.includes(lscCode) && (filterCount === null || filterCount === undefined || filterCount <= 0)) {
            filterDisabled = true;
        }
    }
    return filterDisabled;
};

// Check Filter Options Updates.
const checkLSCUpdates = (queryList, lscCode) => {
    let useLscData = false;
    if (includeLSCFilterUpdates.includes(lscCode)) {
        const hasMakeValue = buildValue(queryList?.makeCode)?.length > 0;
        const hasModelValue = buildValue(queryList?.modelCode)?.length > 0;
        const hasBodyStyleValue = buildValue(queryList?.vehicleStyleCode)?.length > 0;
        if (hasMakeValue || hasModelValue || hasBodyStyleValue) {
            useLscData = true;
        }
    }
    return useLscData;
};

// Build Dynamic Filters for Electric or Truck
export const buildDynamicFilterSubTypeOption = async (referenceCode, lscData, lscCode, selectedFilterSubValue, label, filterList, subType, queryList) => {
    let dynamicFilterSubType = null;
    const parentOption = filterList?.find((option) => option.value === subType);
    const hasSubType = (filterList?.length === 1) || (parentOption?.selected);

    if (hasSubType) {
        const { payload = [] } = await getReference(referenceCode);
        const options = [];
        payload?.forEach((group) => {
            if (group?.name && group?.code) {
                const filterCount = buildCounts(lscCode, lscData, group);
                const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
                options.push({
                    selected: buildSelected(group.code, selectedFilterSubValue),
                    disabled: disabledValue,
                    count: filterCount,
                    label: group.name,
                    value: group.code,
                });
            }
        });
        dynamicFilterSubType = {
            collapsed: buildCollapsed(selectedFilterSubValue),
            label,
            name: lscCode,
            options,
            value: buildValue(selectedFilterSubValue),
        };
    }
    return dynamicFilterSubType;
};
export const buildFilterYearOption = async (referenceCode, lscData, lscCode, queryList) => {
    const options = [];
    let isLscData = true;
    const useLscData = checkLSCUpdates(queryList, lscCode);
    const payloadLSCData = getLSCData(lscCode, lscData);
    let payloadLSCOptions = getLSCFilterOption(payloadLSCData);

    if (!useLscData) {
        const { payload = [] } = await getReference('years');
        isLscData = false;
        payloadLSCOptions = payload.sort((a, b) => b - a);
        const optionAny = payloadLSCOptions && payloadLSCOptions.find(({ value }) => value === '0');
        if (optionAny === null || optionAny === undefined) {
            options.push(
                { disabled: false, label: 'Any', value: '0' },
            );
        }
    }
    payloadLSCOptions?.forEach((group) => {
        const label = isLscData ? group?.label : group;
        const value = isLscData ? group?.value : group;
        options.push({
            disabled: false,
            label,
            value,
        });
    });
    return options;
};

export const buildFilterMileageOption = async (selectedFilterKey) => {
    const options = [];
    const { payload = [] } = await getReference('mileageRanges');
    const optionAny = payload && payload.find(({ value }) => value === '0');
    if (optionAny === null || optionAny === undefined) {
        options.push(
            { disabled: false, label: 'Any', value: '0' },
        );
    }
    payload?.forEach((group) => {
        if (group?.name && group?.code) {
            const mileageGroup = group.code.replace('-', '');
            options.push({
                disabled: false,
                selected: buildSelected(mileageGroup, selectedFilterKey),
                label: group.name,
                value: group.code.replace('-', ''),
            });
        }
    });
    return options;
};

// Build reference Filters with LSC count and Update LSC options when Make/Model/BodyStyle selected/Modified
export const buildFilterOption = async (referenceCode, lscData, lscCode, queryList) => {
    const selectedFilterKey = _get(queryList, lscCode, null);
    const options = [];
    let isLscData = true;
    const useLscData = checkLSCUpdates(queryList, lscCode);
    const payloadLSCData = getLSCData(lscCode, lscData);
    let payloadLSCOptions = getLSCFilterOption(payloadLSCData);

    if (!useLscData) {
        // Load Search Options data when no LSC updates
        const { payload = [] } = await getReference(referenceCode);
        payloadLSCOptions = payload;
        isLscData = false;
    }

    payloadLSCOptions?.forEach((group) => {
        const label = isLscData ? group?.label : group?.name;
        const value = isLscData ? group?.value : group?.code;
        if (label && value) {
            const filterCount = isLscData ? group.count : buildCounts(lscCode, lscData, group);
            const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
            options.push({
                selected: buildSelected(value, selectedFilterKey),
                disabled: disabledValue,
                count: filterCount,
                label,
                value,
            });
        }
    });
    return options;
};

// For Features
export const buildFilterOptionGroup = async (referenceCode, lscData, lscCode, queryList) => {
    let optionsGroups = [];
    let expansionGroupSubIndex = [];
    const useLscData = checkLSCUpdates(queryList, 'featureCodeSubGroup');
    const payloadLSCData = getLSCData(lscCode, lscData);
    // LSC Option Groups
    optionsGroups = (payloadLSCData && _get(payloadLSCData, 'optionsGroups', [])) || [];

    if (!useLscData) {
        optionsGroups = [];
        // Load Search Options, no updates from LSC
        const { payload = [] } = await getReference(referenceCode);
        let displayGroup = null;

        payload?.forEach((group, index) => {
            if (group?.displayGroup) {
                if (displayGroup === null) {
                    displayGroup = group.displayGroup;
                }

                if (displayGroup !== null && group.displayGroup !== displayGroup) {
                    optionsGroups.push({
                        title: displayGroup,
                        expansionGroupSubIndex,
                    });

                    displayGroup = group.displayGroup;
                    expansionGroupSubIndex = [];
                }
                expansionGroupSubIndex.push(
                    index
                );

                if (index === payload.length - 1) {
                    optionsGroups.push({
                        title: displayGroup,
                        expansionGroupSubIndex,
                    });
                }
            }
        });
    }
    return optionsGroups;
};
const sortMMTFilters = (codes) => {
    codes = codes?.sort((a, b) => {
        if (a?.name?.localeCompare && b?.name) {
            return a.name.localeCompare(b.name);
        }
        return 0;
    });
    return codes || [];
};

// Build Models filter for Series and Non Series Patterns
const buildModelFilterOption = async (makeCode, modelList, queryList, lscFilterGroupsData, lscCode, isFilterGroups) => {
    let { payload: modelCodes = [] } = await getReference('modelCode', { makeCode });
    const modelName = isFilterGroups ? makeCode : `modelCode|${makeCode}`;
    const modelLabel = `${makeCode} Models`;
    const selectedModelQueryList = buildValue(queryList?.modelCode);
    const selectedModelList = [];
    const modelOptions = [];
    modelCodes = sortMMTFilters(modelCodes);
    modelCodes?.forEach((group) => {
        if (group?.name && group?.code) {
            const selectedValues = buildSelected(group.code, selectedModelQueryList);
            if (selectedValues) {
                selectedModelList.push(group.code);
            }
            const filterCount = buildSeriesModelsCounts('modelCode', lscFilterGroupsData, group, makeCode);
            const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
            modelOptions.push({
                selected: selectedValues,
                disabled: disabledValue,
                count: filterCount,
                label: group.name,
                value: group.code,
            });
        }
    });
    const modelNode = {
        name: modelName,
        modelObj: {
            collapsed: false,
            label: modelLabel,
            name: modelName,
            options: modelOptions,
            value: selectedModelList,
        },
    };
    modelList[modelNode.name] = modelNode.modelObj;
};

const buildSeriesFilterOption = async (makeCode, seriesList, modelList, queryList, lscFilterGroupsData, lscCode, seriesCodes, isFilterGroups) => {
    const seriesName = isFilterGroups ? makeCode : `seriesCode|${makeCode}`;
    const seriesLabel = `${makeCode} Series`;
    if (seriesCodes.length) {
        const selectedSeriesQueryList = buildValue(queryList?.seriesCode);
        const selectedSeriesList = [];
        const options = [];
        seriesCodes = sortMMTFilters(seriesCodes);
        seriesCodes?.forEach((group) => {
            if (group?.name && group?.code) {
                const selectedValues = buildSelected(group.code, selectedSeriesQueryList);
                if (selectedValues) {
                    selectedSeriesList.push(group.code);
                }
                const filterCount = buildSeriesModelsCounts('seriesCode', lscFilterGroupsData, group, makeCode);
                const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
                options.push({
                    selected: selectedValues,
                    disabled: disabledValue,
                    count: filterCount,
                    label: group.name,
                    value: group.code,
                });
            }
        });
        const seriesNode = {
            name: seriesName,
            seriesObj: {
                collapsed: false,
                label: seriesLabel,
                name: seriesName,
                options,
                value: selectedSeriesList,
            },
        };
        seriesList[seriesNode.name] = seriesNode.seriesObj;
        // With series Models
        await buildModelFilterOption(makeCode, modelList, queryList, lscFilterGroupsData, lscCode, isFilterGroups);
    }
};

// Build Series with Models and Non Series pattern.
export const buildModelSeriesFilterOption = async (lscData, lscCode, queryList, lscFilterGroupsData, isFilterGroups) => {
    let makeCodeList = [];
    const modelList = [];
    const seriesList = [];
    let hasSeriesCode = false;
    makeCodeList = buildValue(queryList?.makeCode);
    if (makeCodeList.length > 0) {
        await Promise.all(makeCodeList.map(async (makeCode) => {
            const { payload: seriesCodes = [] } = await getReference('seriesCode', { makeCode });
            if (seriesCodes.length) {
                hasSeriesCode = true;
                await buildSeriesFilterOption(makeCode, seriesList, modelList, queryList, lscFilterGroupsData, lscCode, seriesCodes, isFilterGroups);
            } else {
                // Non Series Models
                await buildModelFilterOption(makeCode, modelList, queryList, lscFilterGroupsData, lscCode, isFilterGroups);
            }
        }));
    }
    let retObj = null;
    if (hasSeriesCode) {
        if (seriesList) {
            retObj = {
                seriesCode: seriesList,
                modelCode: modelList,
            };
        }
    } else if (modelList) {
        retObj = {
            modelCode: modelList,
        };
    }
    return retObj;
};

const filterModels = (arr, query) => arr.filter((model) => model?.code === query);

export const buildTrimFilterOption = async (lscData, lscCode, queryList, lscFilterGroupsData, isFilterGroups) => {
    let makeCodeList = [];
    const trimList = [];
    makeCodeList = buildValue(queryList?.makeCode);
    const modelList = buildValue(queryList?.modelCode);
    const selectedTrimQueryList = buildValue(queryList?.trimCode);

    await Promise.all(modelList?.map(async (modelCode) => {
        const options = [];
        const selectedTrimList = [];
        makeCodeList?.map(async (makeCode) => {
            const { payload: modelCodes = [] } = await getReference('modelCode', { makeCode });
            const filteredModels = modelCodes.length && filterModels(modelCodes, modelCode);
            const modelName = filteredModels.find(({ code }) => code === modelCode)?.name || modelCode;
            let modelTrims = filteredModels.length && filteredModels[0]?.trims;
            modelTrims = modelTrims?.length && sortMMTFilters(modelTrims);
            modelTrims?.forEach((trim) => {
                if (trim?.name && trim?.code) {
                    const selectedValues = buildSelected(`${modelCode}|${trim.code}`, selectedTrimQueryList);
                    if (selectedValues) {
                        selectedTrimList.push(`${modelCode}|${trim.code}`);
                    }
                    const filterCount = buildTrimCounts(lscCode, lscFilterGroupsData, trim, modelCode);
                    const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
                    options.push({
                        selected: selectedValues,
                        disabled: disabledValue,
                        count: filterCount,
                        label: trim.name,
                        value: `${modelCode}|${trim.code}`,
                    });
                }
            });
            const trimName = isFilterGroups ? modelCode : `trimCode|${modelCode}`;
            const trimNode = {
                name: trimName,
                trimObj: {
                    collapsed: buildCollapsed(modelCode),
                    label: `${modelName} Trim`,
                    name: trimName,
                    options,
                    value: selectedTrimList,
                },
            };
            trimList[trimNode.name] = trimNode.trimObj;
        });
    }));

    let retObj = null;

    if (trimList) {
        retObj = {
            trimCode: trimList,
        };
    }

    return retObj;
};
const getPackages = async (makeCode, modelCode, selectedTrimQueryList, lscFilterGroupsData, lscCode, queryList, isFilterGroups, packageList) => {
    const selectedPackageList = [];
    const selectedPkgQueryList = buildValue(queryList?.packageName);
    await Promise.all(selectedTrimQueryList.map(async (trim) => {
        const options = [];
        if (trim.length && trim.indexOf('|') !== -1) {
            const trimModelCode = trim.substring(0, trim.indexOf('|'));
            const trimCode = trim.substring(trim.indexOf('|') + 1);

            if (trimModelCode === modelCode) {
                if (isValidCode(makeCode) && isValidCode(modelCode) && isValidCode(trimCode)) {

                    const packagesPayload = await refPackageFilterFetcher({ makeCode, modelCode, trimCode });
                    const packages = packagesPayload?.results?.packageNames;

                    packages?.forEach((item) => {

                        if (item?.name && item?.code) {
                            const filterCount = buildPackageCount(lscCode, lscFilterGroupsData, trim, item.code);
                            const selectedValues = buildSelected(item.code, selectedPkgQueryList);
                            if (selectedValues) {
                                selectedPackageList.push(item.code);
                            }
                            const disabledValue = buildFilterDisabled(lscCode, filterCount, queryList);
                            options.push({
                                selected: selectedValues,
                                disabled: disabledValue,
                                count: filterCount,
                                label: item.name,
                                value: item.code,
                            });
                        }
                    });
                }

                const packageName = isFilterGroups ? `${modelCode}|${trimCode}` : `packages|${modelCode}|${trimCode}`;
                const packageNode = {
                    name: packageName,
                    packageObj: {
                        collapsed: true,
                        label: `${trimCode} Package`,
                        name: packageName,
                        options,
                        value: selectedPackageList,
                    },
                };
                if (options.length > 0) {
                    packageList[packageNode.name] = packageNode.packageObj;
                }
            }
        }
    }));
    return packageList;
};

const buildPackages = async (selectedTrimQueryList, modelList, makeCodeList, lscFilterGroupsData, lscCode, queryList, isFilterGroups, packageList) => {
    if (selectedTrimQueryList) {
        await Promise.all(modelList.map(async (modelCode) => {
            await Promise.all(makeCodeList?.map(async (makeCode) => {
                const { payload: modelCodes = [] } = await getReference('modelCode', { makeCode });

                if (modelCodes) {
                    const filteredModels = filterModels(modelCodes, modelCode);
                    if (filteredModels && filteredModels.length > 0) {
                        packageList = await getPackages(makeCode, modelCode, selectedTrimQueryList, lscFilterGroupsData, lscCode, queryList, isFilterGroups, packageList);
                    }
                }
            }));
        }));
    }
    return packageList;
};

export const buildPackagesFilterOption = async (lscData, lscCode, queryList, lscFilterGroupsData, isFilterGroups) => {
    let retObj = null;
    const makeCodeList = buildValue(queryList?.makeCode);
    const modelList = buildValue(queryList?.modelCode);
    const selectedTrimQueryList = buildValue(queryList?.trimCode);
    let packageList = [];
    packageList = await buildPackages(selectedTrimQueryList, modelList, makeCodeList, lscFilterGroupsData, lscCode, queryList, isFilterGroups, packageList);

    if (packageList) {
        retObj = {
            packages: packageList,
        };
    }

    return retObj;
};

export default {
    buildFilterOption,
    buildFilterOptionGroup,
    buildModelSeriesFilterOption,
    buildTrimFilterOption,
    buildFilterYearOption,
    buildFilterMileageOption,
    buildDynamicFilterSubTypeOption,
    buildPackagesFilterOption,
    buildValue,
    buildFordValue,
    buildFordSelected,
    buildCollapsed,
};
