import { fetchJSON } from '@bonnet/next/fetch';

export default ({ vinsParam = '', channel = 'ATC' }) => {

    const options = {
        circuitBreaker: {
            id: 'activeListings',
            timeout: 3000,
            resetTimeout: 30000,
            fallback: {},
        },
        headers: {
            'X-fwd-svc': 'atc',
        },
        query: {
            collapseFilters: true,
            vin: vinsParam,
            channel,
        },
    };

    return fetchJSON('/rest/lsc/listing', options);
};
