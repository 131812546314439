import React, { useCallback } from 'react';

import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { srpFixedAdDuck } from '@/ducks/srp';

import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

export function FixedAdWaypointContainer({
    actions,
    children,
    cmp,
    onLeave,
    onEnter,
    onPositionChange,
    state,
}) {
    actions = {
        ...actions,
        showFixedAd: () => actions.updateFixedAd({
            showFixedAd: true,
        }),
        hideFixedAd: () => actions.updateFixedAd({
            showFixedAd: false,
        }),
        refreshFixedAd: (adPos) => actions.updateFixedAd({
            adPos,
        }),
    };

    const handleOnLeave = useCallback((props) => {
        if (onLeave) {
            onLeave({ state, actions, ...props });
        }
    }, [actions, onLeave, state]);

    const handleOnEnter = useCallback((props) => {
        if (onEnter) {
            onEnter({ state, actions, ...props });
        }
    }, [actions, onEnter, state]);

    const handleOnPositionChange = useCallback((props) => {
        if (onPositionChange) {
            onPositionChange({ state, actions, ...props });
        }
    }, [actions, onPositionChange, state]);

    const dataCmp = `${cmp}FixedAdTrigger`;

    return (
        <DisableAdsFeatureFlag>
            <Waypoint
                data-cmp={dataCmp}
                onLeave={handleOnLeave}
                onEnter={handleOnEnter}
                onPositionChange={handleOnPositionChange}
            >
                <span>
                    {children}
                </span>
            </Waypoint>
        </DisableAdsFeatureFlag>
    );
}

const mapDispatchToProps = mapDispatchToActionProp({
    updateFixedAd: srpFixedAdDuck.creators.setKeys,
});

export default connect(null, mapDispatchToProps)(FixedAdWaypointContainer);
