import React, { memo, useCallback, useEffect } from 'react';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    Image,
    SponsoredText,
    Text,

} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { InventoryListingV2, PromoCard } from 'reaxl-listing';

const eventType = {
    incentive: 'incentives',
    reduced: 'price-reduced',
    newlylisted: 'newly-listed',
    numviewed: 'number-viewed',
};

function PremiumSpotlightContainer({
    premiumSpotlight,
    urgencyDriverData,
    getListingProps,
    listingIndex,
}) {

    const { sendImpressions } = useAnalytics();

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const sendListingImpression = useCallback(() => {
        sendImpressions({
            name: 'inventoryImpressions',
            data: {
                cmp: eventType[urgencyDriverData.type],
                selectedItemIds: [premiumSpotlight.id],
            },
        });
    }, [premiumSpotlight.id, sendImpressions, urgencyDriverData.type]);

    useEffect(() => {
        if (premiumSpotlight) {
            sendListingImpression();
        }
    }, [premiumSpotlight, premiumSpotlight.id, sendImpressions, sendListingImpression]);

    const renderMobileUrgencyDriver = () => (
        <div className="display-flex align-items-center">
            <Image
                height={38}
                width={38}
                src={urgencyDriverData.imgSrc}
                alt=""
            />
            <div className="margin-left-3">
                <Text
                    componentClass="div"
                    color="blue-darker"
                    weight="bold"
                    size={400}
                >
                    {urgencyDriverData.heading}
                </Text>
                <Text
                    color="subdued"
                    componentClass="div"
                >
                    {urgencyDriverData.subText}
                </Text>
            </div>
        </div>
    );

    const renderContent = () => (
        <div
            className={clsx({ 'padding-horizontal-4': isXs, 'padding-horizontal-2': !isXs }, 'row')}
            style={{ height: '100%', width: '100%' }}
        >
            <div
                className="col-sm-6 col-xs-12 display-flex align-items-center"
                style={{ minWidth: isXs ? '70%' : 'inherit', height: !isXs ? '100%' : '10%' }}
            >
                {!isXs
                    ? (
                        <PromoCard
                            data-cmp="spotlightPromoCard"
                            className="justify-content-center"
                            uiContext="spotlight"
                            {...urgencyDriverData}
                        />
                    )
                    : renderMobileUrgencyDriver()}
            </div>
            <div
                className="col-sm-6 col-xs-12 align-self-center text-left padding-bottom-4 padding-top-1"
                style={{ minWidth: isXs ? '70%' : 'inherit', height: isXs ? '90%' : '100%' }}
            >
                <SponsoredText />
                <InventoryListingV2
                    uiContext="simplified"
                    style={{ height: '100%' }}
                    {...getListingProps('peekaboo', premiumSpotlight, listingIndex)}
                    className="bg-gray-lightest cursor-pointer display-flex "
                />
            </div>
        </div>
    );

    return (
        <div className="col-sm-8 col-xs-12 display-flex flex-column">
            <div
                data-cmp="PremiumSpotlightContainer"
                key="PremiumSpotlightContainer"
                className="panel panel-default bg-periwinkle display-flex justify-content-around align-items-center flex-grow padding-bottom-3"
            >
                {renderContent()}
            </div>
        </div>
    );
}

export default memo(PremiumSpotlightContainer);
