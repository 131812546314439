import React, { memo, useCallback, useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';

import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { Button } from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import {
    authDuck,
    userDuck,
} from '@/ducks';

import {
    srpFiltersDuck,
    srpResultsDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function InventoryClearFiltersComponent({
    actions: {
        clearFilters = () => { },
    },
    collapsed = false,
    className,
}) {
    const {
        // Feature configs
        filter_chips_srp: [enableFilterChips],
        global_personalization: [isGlobalPersonalizationEnabled],
        personalization: [enablePersonalization],
    } = useFeatures([
        'filter_chips_srp',
        'global_personalization',
        'personalization',
    ]);

    const { sendClick } = useAnalytics();
    const navigateToSrp = useSrpNavigation();

    const hasResults = useSelector(srpResultsDuck.selectors.hasActiveResults);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    const selectedFilterChips = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);
    const [activeFilterChips, setActiveFilterChips] = useState(selectedFilterChips);

    useEffect(() => {
        if (hasResults) {
            setActiveFilterChips(selectedFilterChips);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasResults, selectedFilterChips]);

    const onOptionClearAllChips = useCallback(async (event, clearFiltersLabel) => {
        sendClick('clearAllFiltersClick', event, {
            clearFiltersLabel,
        });

        // update redux state
        let keys;
        keys = Object.keys(filtersValues);
        keys = keys.filter((filters) => filters !== 'searchRadius');
        await clearFilters(keys);

        navigateToSrp({ resetPagination: true, isNewSearch: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendClick, clearFilters, navigateToSrp]);

    const clearFiltersLabel = 'Clear Filters';

    const isDesktopCarousel = !collapsed;

    const search = useSelector(userDuck.selectors.getSavedSearch);
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const requestValuesWithoutSort = useSelector(srpFiltersDuck.selectors.getRequestValues, _isEqual);

    const isSearchSavable = !isLoggedIn || !(search.title && search.title.length);
    const hasSearchParams = !!(requestValuesWithoutSort && Object.keys(requestValuesWithoutSort).length);
    const showSaveSearchLink = (enablePersonalization || isGlobalPersonalizationEnabled) && hasSearchParams
        && isSearchSavable;

    const linkClasses = clsx({
        'display-flex text-nowrap align-self-center': isDesktopCarousel,
    }, className);

    const renderPipe = (showSaveSearchLink || collapsed) && (
        <span className="margin-horizontal-2">|</span>
    );

    return (enableFilterChips && activeFilterChips.length > 0) ? (
        <span className={linkClasses}>
            { renderPipe }
            <Button
                key="filterChipClearFilters"
                onClick={(event) => onOptionClearAllChips(event, clearFiltersLabel)}
                className="text-nowrap"
                bsStyle="text"
            >
                {clearFiltersLabel}
            </Button>
        </span>
    ) : null;
}

export const InventoryClearFilters = memo(InventoryClearFiltersComponent, (oldProps, newProps) => _isEqual(newProps, oldProps));

const mapDispatchToProps = mapDispatchToActionProp({
    clearFilters: srpFiltersDuck.creators.clearFilters,
});

export default connect(null, mapDispatchToProps)(InventoryClearFilters);
