import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ducks
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
    kbbVRSDataDuck,
    preorderDuck,
} from '@/ducks';

import {
    srpActiveInteractionDuck,
} from '@/ducks/srp';

import getPreorderAvailability from './getPreorderAvailability';

export const isCurrentModelAvailable = (currentMake, currentModel, vrsData) => {
    let isAvailable = false;

    if (currentMake && currentModel) {
        if (Array.isArray(currentModel)) {
            currentModel.forEach((model) => {
                if (!_isEmpty(vrsData?.[currentMake]?.[model])) {
                    isAvailable = true;
                }
            });
        } else if (!_isEmpty(vrsData?.[currentMake]?.[currentModel])) {
            isAvailable = true;
        }
    }
    return isAvailable;
};

const buildMakeModelPairs = ({ makeCode, modelCode }) => {
    const pairs = [];
    const years = [new Date().getFullYear(), new Date().getFullYear() + 1];

    years.forEach((year) => {
        pairs.push({
            makeCode,
            modelCode,
            makeModel: `${makeCode}-${modelCode}-${year}`,
            year,
        });
    });

    return pairs;
};

const useCompareTrimsData = (makeModelData) => {
    const dispatch = useDispatch();

    const activeModel = _get(useSelector(srpActiveInteractionDuck.selectors.getDuckState), 'activeModel', '');
    const activeTrims = useSelector(kbbVRSDataDuck.selectors.getActiveModelTrims);
    const compareTrimsData = useSelector(kbbVRSDataDuck.selectors.getCompareTrims);

    const loadTrimData = (options) => dispatch(preorderDuck.creators.loadTrimData(options));
    const loadVehicleSpecs = () => dispatch(kbbVRSDataDuck.creators.loadVehicleSpecs());
    const loadVrsData = (options) => dispatch(kbbVRSDataDuck.creators.loadData(options));
    const setActiveInteraction = (options) => dispatch(srpActiveInteractionDuck.creators.setKeys(options));

    const showCompareTrimLink = getPreorderAvailability(makeModelData)
        && isCurrentModelAvailable(makeModelData.makeCode, makeModelData.modelCode, compareTrimsData);

    useEffect(() => {
        if (getPreorderAvailability(makeModelData)) {
            const makeModelPairs = buildMakeModelPairs(makeModelData);

            loadVrsData({
                enableFetchOptions: false,
                key: 'compareTrims',
                makeModelPairs,
                selectedModel: activeModel,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeModel]);

    // fetch vehicle specs for each trim and load trim data into preorder store
    useEffect(() => {
        if (showCompareTrimLink) {

            loadVehicleSpecs();
            loadTrimData({ activeTrims });

            const activeTrimsFuelTypes = Array.from(new Set(activeTrims.map((trim) => trim.engineType)));

            if (activeTrimsFuelTypes.length > 0) {
                setActiveInteraction({
                    fuelTypeOptions: activeTrimsFuelTypes.map((value) => {
                        const label = value === 'Gas' ? 'Gasoline' : value;
                        return { label, value };
                    }),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compareTrimsData]);

    return showCompareTrimLink;
};

export default useCompareTrimsData;
