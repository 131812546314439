const spotlightUrgencyDrivers = {
    incentive: 'incentives',
    reduced: 'price-reduced',
    newlylisted: 'newly-listed',
    numviewed: 'number-viewed',
};

/*
 * helper method to determine the clickType to appended to the listing's href
 */
export const getClickType = (tierName, spotlightType) => {
    let listingClickType;
    switch (tierName) {
        case 'supplemental':
            listingClickType = 'supplemental';
            break;
        case 'spotlight':
            listingClickType = 'spotlight';
            break;
        case 'boost':
            listingClickType = 'ncb';
            break;
        case 'ime':
            listingClickType = 'ime';
            break;
        case 'peekaboo':
            listingClickType = spotlightUrgencyDrivers[spotlightType];
            break;
        default:
            listingClickType = 'listing';
    }
    return listingClickType;
};

export const getParentId = (listingCategory, index, spotlightType) => {
    let parId;
    switch (listingCategory) {
        case 'supplemental':
            parId = 'sup_v_lstg';
            break;
        case 'spotlight':
            parId = 'spv_lstg';
            break;
        case 'boost':
            parId = 'ncb';
            break;
        case 'ime':
            parId = 'ime';
            break;
        case 'peekaboo':
            parId = spotlightUrgencyDrivers[spotlightType];
            break;
        default:
            parId = 'v_lstg';
    }

    return listingCategory === 'peekaboo' ? parId : parId + '_' + (index + 1);
};
