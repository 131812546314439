import React from 'react';

/**
 * This component is an accessible jump link that will be hidden until focused.
 *
 * @param {message}: the link message ( defaults to  "skip to main content")
 * @param {id}: the target element ID
 * @returns anchor element
 */

function JumpLink({ message = 'Skip to main content', id }) {
    return (
        <a
            href={id}
            tabIndex={0}
            className="sr-only sr-only-focusable"
        >
            {message}
        </a>
    );
}

export default JumpLink;
