import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

const srpBreadcrumbsDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'breadcrumbs',
    initialState: {
        sorted: [],
        schemaItems: [],
    },
}).extend({
    selectors: () => ({
        getSchemaItems: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ schemaItems }) => schemaItems,
        )),
        getBreadcrumbs: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ sorted }) => sorted,
        )),
    }),
});

export default srpBreadcrumbsDuck;
