export default function withSrpFetchCompositeJSON() {
    return async (ctx) => {

        // destructure the base response and spread it across the top level
        // while merging the other response data?
        const { base = {}, ...rest } = await ctx.fetchCompositeJSON.fetchComposite();

        Object.assign(ctx.data, {
            ...rest,
            ...base,
        });

    };

}
