import { fetchJSON } from '@bonnet/next/fetch';

import { getReference } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

export default function withKbbEditorialFetcher() {
    return async (ctx) => {
        if (ctx.data.brand === brands.KBB_BRAND) {

            const { makeCodeList, modelCodeList, startYear, endYear } = ctx.query;
            const kbbParams = [];
            const showReviews = ![makeCodeList, modelCodeList].some((value) => Array.isArray(value) && value.length > 1);

            if (showReviews) {
                const { payload: makePayload = [] } = await getReference('makeCode');
                const { kbbCode: makeId } = makePayload.find(({ code }) => makeCodeList === code) || {};

                if (makeId) {
                    kbbParams.push(makeId);
                }

                if (modelCodeList && makeCodeList) {
                    const { payload: modelPayload = [] } = await getReference('modelCode', { makeCode: makeCodeList });
                    const { kbbCode: modelId } = modelPayload.find(({ code }) => modelCodeList === code) || {};

                    if (modelId) {
                        kbbParams.push(modelId);
                    }
                }

                if (startYear && (startYear === endYear)) {
                    kbbParams.push(endYear);
                }

                ctx.fetchCompositeJSON.addFetch('editorialReviews', fetchJSON('https://www.kbb.com/wp-json/wp/v2/kbb_expert_review/', {
                    query: {
                        ymmt: kbbParams.join('/'),
                        _embed: true,
                    },
                    circuitBreaker: {
                        id: 'wp-expert-review',
                        timeout: 3000,
                        resetTimeout: 30000,
                        fallback: {},
                    },
                }));

            }
        }
    };
}
