import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'redirectPage',
}).extend({
    selectors: () => ({
        getRedirectExpiredPage: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).redirectExpiredPage),
    }),
});
