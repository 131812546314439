import _get from 'lodash/get';

import { getPathSRP } from '@/utilities';

import { getKeys, translateKeys } from '@atc/bonnet-parameters';
import { getIndexedLocationFilters, getReference } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

import { getCrawlpathDataMaps } from '@/utilities/CrawlpathDataMaps';

import additionalCrawlPaths from '@/config/crawlAdditional';
import additionalCrawlPathsFord from '@/config/crawlAdditionalFord';
import additionalCrawlPathsKbb from '@/config/crawlAdditionalKbb';

import {
    srpAdditionalCrawlPathsDuck,
    srpCitiesCrawlPathsDuck,
    srpCrawlPathsDataMapsDuck,
    srpListingTypesCrawlPathsDuck,
    srpMakeCrawlPathsDuck,
    srpModelCrawlPathsDuck,
    srpTrimCrawlPathsDuck,
    srpYearCrawlPathsDuck,
} from '@/ducks/srp';

const getMakeData = () => ({
    path: 'makesCrawlPaths',
    list: ['links'],
});

const getModelData = () => ({
    path: 'modelCrawlPaths',
    list: ['links'],
});

const getYearsData = () => ({
    path: 'yearCrawlPaths',
    list: ['links'],
});

const getCitiesData = () => ({
    path: 'citiesCrawlPaths',
    list: ['links'],
});

const getListingTypesData = () => ({
    path: 'listingTypesCrawlPaths',
    list: ['links'],
});

const getTrimsData = () => ({
    path: 'trimCrawlPaths',
    list: ['links'],
});

// NOTE: Gets the code for make or model from reference data
const getCode = (value, { success, payload }) => {
    if (success) {
        const filteredItem = payload.filter((item) => item.name === value);

        if (filteredItem?.length === 1) {
            return filteredItem[0].code;
        }
    }

    return '';
};

const buildQuery = async (query, item, storeKey, referenceData) => {
    // Accepted params: make, model, trim, vehicleStyle, listingType, location
    let crawlPathQuery = {
        makeCode: query.makeCode,
        modelCode: query.modelCode,
        trimCode: query.trimCode,
        vehicleStyleCode: query.vehicleStyleCode,
        listingType: query.listingType,
        city: query.city,
        state: query.state,
        startYear: query.startYear,
        endYear: query.startYear,
        zip: query.zip,
    };

    switch (storeKey) {
        case 'citiesData':
            crawlPathQuery = { ...crawlPathQuery, city: item.city, state: item.state, zip: item.zip };
            break;
        case 'makesData':
            crawlPathQuery = { ...crawlPathQuery, makeCode: getCode(item.value, referenceData) };
            break;
        case 'modelData':
            crawlPathQuery = { ...crawlPathQuery, modelCode: getCode(item.value, referenceData) };
            break;
        case 'yearsData':
            crawlPathQuery = { ...crawlPathQuery, startYear: item.value, endYear: item.value };
            break;
        case 'trimData':
            crawlPathQuery = { ...crawlPathQuery, trimCode: item.value };
            break;
        case 'listingTypeData':
            crawlPathQuery = { ...crawlPathQuery, listingType: item.value };
            break;
        default:
            break;
    }

    return crawlPathQuery;
};

// update a collection of data with  a CS formatted path
// using a supplied query and dynamic parameter value
const updateWithPath = async ({ data = [], query = {}, brand, target, storeKey, referenceData = {} }) => {
    // only when brand is ford & makesdata
    if (brand === brands.FORD_BRAND && storeKey === 'makesData') {
        delete query.city;
        delete query.state;
        delete query.zip;
    }

    let queryCopy = { ...query };

    const buildPromises = data.map(async (item) => {
        if (item.codes) {
            if (item.codes.startYear && item.codes.endYear) {
                item.codes.startYear = item.codes.startYear[0];
                item.codes.endYear = item.codes.endYear[0];
            }
        }

        queryCopy = await buildQuery(query, item, storeKey, referenceData);

        // zip is unnecessary since it will always be the center zip for crawlpath links
        if (queryCopy.zip) {
            delete queryCopy.zip;
        }

        // remove location data unless we are building cities crawl path links
        if (storeKey !== 'citiesData') {
            delete queryCopy.state;
            delete queryCopy.city;
            delete queryCopy.location;
            delete queryCopy.searchRadius;
            delete queryCopy.dma;
        }

        const path = await getPathSRP({
            ...translateKeys(queryCopy, { target }),
            ...item.codes,
        }, {
            target,
            brand,
            basePath: true,
        });

        return {
            ...item,
            link: path,
        };
    });

    return Promise.all(buildPromises);
};

// utility to inspect if there is crawlPath data to parse
// if not then reset the crawlPath state to hide the data
// essentially used to hide the crawl path on subsequent srp renders from filter changes
const processCrawlPathData = async ({ ctx, duck, dataPath, storeKey, lists, disableTrim = false, ...rest }) => {
    const data = _get(ctx.data, dataPath, false);

    if (data) {
        // update the data with paths built by bonnet-paths
        const updatePromises = lists.map(async (key) => ({ [key]: await updateWithPath({ data: data[key], brand: ctx.data.brand, target: 'cs', storeKey, ...rest }) }));
        const updatedLists = await Promise.all(updatePromises);

        // update the data set with the updated lists with paths
        const updatedData = {
            ...data,
            ...updatedLists.reduce((acc, list) => ({ ...acc, ...list }), {}),
            disableTrim,
        };

        return ctx.store.dispatch(duck.creators.setKey(storeKey, updatedData));
    }

    return ctx.store.dispatch(duck.creators.reset());
};

// TODO delete this file in favor of utilities/processCrawlPath.js when enable_crawlpath_migration goes live
export default function withCrawlPath() {
    return async (ctx) => {
        const {
            enable_crawlpath_migration: [enableCrawlPathMigration],
            enable_indexed_locations: [enableIndexedLocations],
        } = ctx.useFeatures([
            'enable_crawlpath_migration',
            'enable_indexed_locations',
        ]);

        if (enableCrawlPathMigration) {
            return;
        }

        const targetKey = 'lsc';

        const listingTypeKey = getKeys('listingType')[targetKey];
        const makeCodeKey = getKeys('makeCode')[targetKey];
        const modelCodeKey = getKeys('modelCode')[targetKey];
        const trimCodeKey = getKeys('trimCode')[targetKey];
        const styleCodeKey = getKeys('vehicleStyleCodes')[targetKey];
        const { brand } = ctx.data;

        const queryList = {
            [listingTypeKey]: ctx.query[listingTypeKey],
            [makeCodeKey]: ctx.query[makeCodeKey],
            [modelCodeKey]: ctx.query[modelCodeKey],
            [trimCodeKey]: ctx.query[trimCodeKey],
            [styleCodeKey]: ctx.query[styleCodeKey],
            city: ctx.query.city,
            state: ctx.query.state,
            zip: ctx.query.zip,
            startYear: ctx.query.startYear,
            endYear: ctx.query.endYear,
            fuelTypeGroup: ctx.query.fuelTypeGroup,
        };

        const { srpSelfServiceSeo } = ctx;
        const {
            indexable_locations: indexableLocations = 'high',
        } = srpSelfServiceSeo || {};
        const locationIndexValue = enableIndexedLocations ? indexableLocations : 'all';

        let indexedZip = true;
        // Only location pages with zip code in list should show crawlpaths (city crawlpath the exception)
        if (ctx.data.isIndexedZip !== undefined) {
            indexedZip = ctx.data.isIndexedZip;
        } else if (ctx.query.zip) {
            const { success } = await getIndexedLocationFilters(ctx.query, brand, locationIndexValue);

            if (!success) {
                indexedZip = false;
            }
        }

        // Disable some crawl path modules EXCEPT city list when having 2 filters including Body Style
        // This only applied for ATC and Ford
        const disableNonCitiesCrawlpath = !(brand === brands.KBB_BRAND) && !!(queryList.styleCodeKey
            && (queryList.startYear || queryList.endYear || queryList.makeCodeKey || queryList.modelCodeKey || queryList.trimCodeKey));

        // retrieve make and vehiclestyle data for mapping
        const { makesMap, vehicleStylesMap, modelsMap } = await getCrawlpathDataMaps(queryList[makeCodeKey]);
        ctx.store.dispatch(srpCrawlPathsDataMapsDuck.creators.setKey({ makesMap, vehicleStylesMap, modelsMap }));

        const sharedCrawlPathData = {
            ctx,
            query: queryList,
        };

        if (indexedZip) {

            // process make/model crawlpath
            const makes = getMakeData();
            const makesReferenceData = await getReference('makeCode');
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpMakeCrawlPathsDuck,
                dataPath: makes.path,
                storeKey: 'makesData',
                lists: makes.list,
                referenceData: makesReferenceData,
                disableTrim: false,
            });

            // process model crawlpath for lsc
            const models = getModelData();
            const makeKnown = !!queryList[makeCodeKey];
            const modelReferenceData = makeKnown ? await getReference('modelCode', { makeCode: queryList[makeCodeKey] }) : {};
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpModelCrawlPathsDuck,
                dataPath: models.path,
                storeKey: 'modelData',
                lists: models.list,
                referenceData: modelReferenceData,
            });

            // process year crawlpath
            const years = getYearsData();
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpYearCrawlPathsDuck,
                dataPath: years.path,
                storeKey: 'yearsData',
                lists: years.list,
            });

            // process cities crawlpath
            const cities = getCitiesData();
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpCitiesCrawlPathsDuck,
                dataPath: cities.path,
                storeKey: 'citiesData',
                lists: cities.list,
            });

            // process listingType crawlpath
            const listingTypes = getListingTypesData();
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpListingTypesCrawlPathsDuck,
                dataPath: listingTypes.path,
                storeKey: 'listingTypeData',
                lists: listingTypes.list,
            });

            // process trims crawlpath
            const trims = getTrimsData();
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpTrimCrawlPathsDuck,
                dataPath: trims.path,
                storeKey: 'trimData',
                query: {
                    [listingTypeKey]: ctx.query[listingTypeKey],
                    [makeCodeKey]: ctx.query[makeCodeKey],
                    [modelCodeKey]: ctx.query[modelCodeKey],
                    city: ctx.query.city,
                    state: ctx.query.state,
                    zip: ctx.query.zip,
                    startYear: ctx.query.startYear,
                    endYear: ctx.query.endYear,
                },
                lists: trims.list,
            });

            // handle additional crawl path
            const data = (key) => {
                switch (key) {
                    case brands.KBB_BRAND:
                        return additionalCrawlPathsKbb;
                    default:
                        return additionalCrawlPaths;
                }
            };
            if (brand === brands.FORD_BRAND) {
                ctx.store.dispatch(srpAdditionalCrawlPathsDuck.creators.setKeys(additionalCrawlPathsFord));
            } else if (ctx.query.zip || disableNonCitiesCrawlpath) {
                ctx.store.dispatch(srpAdditionalCrawlPathsDuck.creators.reset());
            } else {
                ctx.store.dispatch(srpAdditionalCrawlPathsDuck.creators.setKeys(data(brand)));
            }
        } else {
            // process cities crawlpath for non-canonical pages
            const cities = getCitiesData();
            await processCrawlPathData({
                ...sharedCrawlPathData,
                duck: srpCitiesCrawlPathsDuck,
                dataPath: cities.path,
                storeKey: 'citiesData',
                lists: cities.list,
            });
        }

    };
}
