import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { srpDuckPreset } from '@/ducks/presets';

export default objectDuckCreator({
    ...srpDuckPreset,
    store: 'suggestedFilters',
    initialState: {
        filters: [],
    },
}).extend({
    selectors: (duck) => ({
        getSuggestedFilters: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (localState) => localState.filters,
        )),
    }),
});
