import { formattedNumber } from 'atc-js';

const sortPricingYears = (links) => {
    links = links?.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
    if (links?.length > 11) {
        links = links.splice(11);
    }
    return links || [];
};

const priceFormat = (price) => price && formattedNumber({
    value: price,
    style: 'currency',
    currency: 'USD',
    formatPretty: true,
});

export const getPricingModelList = (modelYearsData, makeName, modelName, trimName) => {
    const newModelYrData = {};
    if (modelYearsData?.length > 0) {
        sortPricingYears(modelYearsData);
        const minYear = modelYearsData[modelYearsData.length - 1].value;
        const maxYear = modelYearsData[0].value;
        newModelYrData.yearRange = minYear + ' - ' + maxYear;
        modelYearsData.map((el) => {
            delete el.count;
            el.formattedAvgPrice = priceFormat(el.avgPrice);
            el.formattedMinPrice = priceFormat(el.startingPrice);
            el.formattedMaxPrice = priceFormat(el.maxPrice);
            el.makeName = makeName;
            el.modelName = modelName;
            el.trimName = trimName;
        });
        newModelYrData.links = modelYearsData;
    }
    return newModelYrData;
};

export const getPricingTrimList = (trimsData, makeName, modelName) => {
    if (trimsData?.length > 0) {
        sortPricingYears(trimsData);
        trimsData.map((el) => {
            delete el.count;
            el.makeName = makeName;
            el.modelName = modelName;
            el.trimCode = (el.value.split('|'))?.[1] || '';
            el.formattedAvgPrice = priceFormat(el.avgPrice);
        });
    }
    return trimsData;
};

export const getYearBasedPricingDetails = (modelYearsData, modelYear) => {
    let yearPricingData = null;
    if (modelYearsData?.length > 0) {
        yearPricingData = modelYearsData?.find((result) => result?.value === modelYear) || null;
    }
    return yearPricingData;
};

export default {
    getPricingModelList,
    getPricingTrimList,
    getYearBasedPricingDetails,
};
