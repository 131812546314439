const getSrpPageTypes = (query) => {
    const { makeCode, modelCode, trimCode, year } = query;

    const hasSingleSelection = (selection) => (Array.isArray(selection) ? selection.length === 1 : !!selection);

    const hasSingleMake = hasSingleSelection(makeCode);
    const hasSingleModel = hasSingleSelection(modelCode);
    const hasSingleTrim = hasSingleSelection(trimCode);

    const isMakeUrl = !!(hasSingleMake && !hasSingleModel && !hasSingleTrim && !year); // make
    const isMMUrl = !!(hasSingleMake && hasSingleModel && !hasSingleTrim && !year); // make/model
    const isMMTUrl = !!(hasSingleMake && hasSingleModel && hasSingleTrim && !year); // make/model/trim
    const isYMMUrl = !!(hasSingleMake && hasSingleModel && !hasSingleTrim && year); // year/make/model
    const isYMMTUrl = !!(hasSingleMake && hasSingleModel && hasSingleTrim && year); // year/make/model/trim

    return { isMakeUrl, isMMUrl, isMMTUrl, isYMMUrl, isYMMTUrl };
};

export default getSrpPageTypes;
