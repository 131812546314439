import React from 'react';

import { LinkIcon, Text } from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { PromoCard } from 'reaxl-listing';

function SellYourCarPlacementContainer() {
    const { brand } = useBrand();

    const offsiteLink = brand === 'atc' ? 'https://www.autotrader.com/sell-my-car/' : 'https://www.kbb.com/sell-your-car/';

    const heading = brand === 'atc' ? 'Sell Your Car on Autotrader' : ' List Your Car on Kelley Blue Book®';

    const offsiteLinkText = (
        <LinkIcon
            className="text-accent"
            placement="after"
        >
            List My Car
        </LinkIcon>
    );
    const handleCtaClick = () => {
        window.open(offsiteLink, 'noopener');
    };

    return (
        <PromoCard
            buttonText={offsiteLinkText}
            buttonStyle={{ width: '90%' }}
            data-cmp="sycPlacement"
            handleButtonClick={handleCtaClick}
            heading={heading}
            imgHeight={62}
            imgSrc="/content/static/img/icon/myWallet/grid/affordable_new_cars.svg"
            subText={<Text componentClass="div">On average, sellers receive 28% more for their vehicle by listing on Private Seller Exchange compared to trade-in.</Text>}
        />
    );
}

export default SellYourCarPlacementContainer;
