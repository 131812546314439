import _get from 'lodash/get';

import {
    srpFiltersDuck,
    srpMarketExtensionDuck,
    srpNewSearchDuck,
    srpPaginationDuck,
    srpSortDuck,
    srpSponsorshipDuck,
} from '@/ducks/srp';

export function getSRPSearch(state) {

    const {
        numRecords,
        currentPage,
    } = srpPaginationDuck.selectors.getDuckState(state);
    const sort = srpSortDuck.selectors.getDuckState(state);
    const marketExtension = srpMarketExtensionDuck.selectors.getDuckState(state);
    const values = srpFiltersDuck.selectors.getRequestQueryValues(state);
    const isNewSearch = srpNewSearchDuck.selectors.getDuckState(state);
    const isSponsorship = srpSponsorshipDuck.selectors.getDuckState(state);

    const psid = _get(state, 'query.psid');
    const siomid = _get(state, 'query.siomid');
    const LNX = _get(state, 'requestParams.lnx');

    const search = {
        ...values,
        isNewSearch,
        marketExtension,
        sortBy: sort,
        numRecords,
        firstRecord: (currentPage - 1) * numRecords,
    };

    if (psid) {
        search.psid = psid;
    }

    if (siomid) {
        search.siomid = siomid;
    }

    if (isSponsorship) {
        search.isSponsorship = isSponsorship;
    } else {
        // Removes the query parameter when false so that it doesn't show in the url at all
        delete search.isSponsorship;
    }

    if (LNX) {
        search.LNX = LNX;
    }

    return search;
}

export default {
    getSRPSearch,
};
