import { brands } from 'reaxl-brand';

/**
 * Google currently treats No Car Found(NCF) pages as soft 404's.
 * this logic makes sure it returns a real response 404 HTTP code so that
 * URLs with no results are lower in priority or not scanned at all, thus excluding them from our crawl budget.
 *
 * This is feature flagged and only limited to Jeep and Chevy searches to evaluate impact before rolling the functionality out to the entire site.
 */
export default function withNoResultsSRP() {
    return async (ctx) => {

        const {
            no_car_found_seo_404: [enableNoCarFound404],
        } = ctx.useFeatures([
            'no_car_found_seo_404',
        ]);

        // If the make is in the array of makes being excluded and totalResultCount is zero, return the appropriate HTTP status while displaying the SRP.
        if (ctx?.data?.totalResultCount === 0 && enableNoCarFound404) {
            if (ctx.data.brand !== brands.FORD_BRAND) {

                // If SEO determines this SRP is valuable for ranking even with no results, return 200, otherwise return 404 to indicate Google should exclude this from our crawlbudget.
                if (ctx.res) {
                    const isHighIndexableLocation = ctx?.srpSelfServiceSeo?.indexable_locations === 'high';
                    const statusCode = isHighIndexableLocation ? 200 : 404;
                    ctx.res.status(statusCode);
                }
            }
        }
    };
}
