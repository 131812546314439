import { fetchJSON } from '@bonnet/next/fetch';

export default function addNewCarBoostData(ctx, lscRequestOptional, shouldFetch) {
    const zip = lscRequestOptional?.query?.zip;
    const isValidZip = zip && typeof zip === 'string' && zip.match(/^\d{5}$/);

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const maxYear = Number(lscRequestOptional?.query?.endYear || currentYear);
    const minYear = Number(lscRequestOptional?.query?.startYear);
    const listingTypes = lscRequestOptional?.query?.listingType;
    const onlyNewIsSelected = listingTypes && typeof listingTypes === 'string' && listingTypes === 'NEW';
    const sellerType = lscRequestOptional?.query?.sellerType;

    const isCurrentMinYear = (minYear === currentYear) || (minYear === nextYear);
    const isOnlyPrivateSellerSelected = !Array.isArray(sellerType) && sellerType === 'p';

    // only fetch boost listing if listing type of New is not the only type selected
    // and max year is not 5 years older than current year
    // min year is not current year or next year and not a private seller SRP
    if (shouldFetch && isValidZip && !onlyNewIsSelected && (currentYear - maxYear < 6) && !isCurrentMinYear && !isOnlyPrivateSellerSelected) {
        // params for endpoint: https://ghe.coxautoinc.com/Autotrader/listing-search-composition/tree/master/src/main/java/com/autotrader/microservice/composition/newcarboost
        const allowedParams = [
            'bodystyleCode',
            'channel',
            'dealerId',
            'makeCode',
            'maxPrice',
            'modelCode',
            'trimCode',
            'seriesCode',
            'vehicleStyleCode',
            'zip',
        ];

        const query = {
            firstRecord: 0,
            numRecords: 2,
        };
        allowedParams.forEach((param) => {
            let key = param;
            const paramsToBoost = ['modelCode', 'trimCode', 'seriesCode'];
            if (paramsToBoost.includes(param)) {
                key = param + 'ToBoost';
            }
            if (lscRequestOptional?.query?.[param]) {
                Object.assign(query, {
                    [key]: lscRequestOptional?.query?.[param],
                });
            }
        });

        const boostEndpoint = '/rest/lsc/newcarboost/listing';
        const boostRequestOptions = {
            ...lscRequestOptional,
            circuitBreaker: {
                ...lscRequestOptional.circuitBreaker,
                id: 'boost',
            },
            query,
        };

        ctx.fetchCompositeJSON.addFetch('boost', fetchJSON(boostEndpoint, boostRequestOptions));
    }
}
