import React, { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconButton } from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { WalletPopover } from 'reaxl-wallet';

import { paymentsDuck, savedInventoryDuck } from '@/ducks';

function MyWalletIconButton() {

    const dispatch = useDispatch();

    const { sendClick } = useAnalytics();
    const targetRef = useRef();

    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds) || [];
    const disableComparePopover = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'disableComparePopover'));
    const showPaymentsPopover = (!disableComparePopover && savedListingIds.length < 1) || disableComparePopover;

    const openWalletModal = () => {
        sendClick('myWalletIconButtonClick');

        dispatch(paymentsDuck.creators.updateMyWalletInteractions({
            paymentEmphasis: true,
            disablePaymentPopover: true,
        }));

        dispatch(paymentsDuck.creators.updateMyWallet({ displayModal: true }));
    };

    return (
        <>
            <div
                data-cmp="wallet-iconbutton"
                ref={targetRef}
            >
                <IconButton
                    glyph="wallet"
                    label="My Wallet"
                    onClick={openWalletModal}
                    aria-label="open My Wallet"
                />
            </div>
            <WalletPopover
                ref={targetRef}
                paymentsDuck={paymentsDuck}
                withPopover={showPaymentsPopover}
            />
        </>
    );
}

export default MyWalletIconButton;
