import _get from 'lodash/get';

const getSRPPrimaryImagesFromCtx = (ctx) => {
    let primaryImages;
    if (_get(ctx, 'data.alpha.defaultTabIndex') === 0) {
        primaryImages = _get(ctx, 'data.alpha.alphaShowcase[0].images');
    }
    if (!primaryImages) {
        primaryImages = _get(ctx, 'data.spotlights.listings[0].images');
    }
    if (!primaryImages) {
        primaryImages = _get(ctx, 'data.listings[0].images');
    }

    return primaryImages;
};

export default getSRPPrimaryImagesFromCtx;
