import { fetchJSON } from '@bonnet/next/fetch';

import { VrsServiceClient } from 'reaxl-wallet';

import { consumerRatingsDuck } from '@/ducks';

import VrsServiceFetcher from '@/fetchers/VrsServiceFetcher';

const vrsServices = new VrsServiceClient({ vrsFetcher: VrsServiceFetcher });

const updateConsumerRatings = async (ctx) => {
    // TODO: Get rid of the legacy params once 100% against LSC
    const { makeCode, modelCode, makeCodeList, modelCodeList } = ctx.query;

    try {
        // If no year is selected then use current year
        const yearId = new Date().getFullYear();

        const querystring = `?yearId=${yearId}&atcMakeCode=${makeCode || makeCodeList}&atcModelCode=${modelCode || modelCodeList}`;
        const { items } = await fetchJSON(`/cars-for-sale/kbbresearch/reference/vehicles${querystring}`, {
            circuitBreaker: {
                id: 'kbb-ref-vehicles',
                timeout: 5000,
                resetTimeout: 300000,
                fallback: {},
            },
        });
        const kbbVehicleId = items?.[0]?.vehicleId || null;

        // Fetch consumer ratings
        let ratings;
        if (kbbVehicleId) {
            (ratings = await vrsServices.getConsumerRatings(kbbVehicleId));
        }

        // Add ratings to redux
        // NOTE: Need to check ratings for undefined/null values to ensure that Object.keys does not throw "TypeError: Cannot convert undefined or null to object"
        if (ratings && Object.keys(ratings)?.length) {
            ctx.store.dispatch(consumerRatingsDuck?.creators?.setKeys(ratings));
        }

    } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }
};

const shouldUpdateRatingsLsc = ({ query }) => {
    const {
        startYear,
        endYear,
        makeCode,
        modelCode,
        trimCode,
        listingType,
        sellerType,
        vehicleStyleCode,
        driveGroup,
        engineCode,
        fuelTypeGroup,
        minPrice,
        maxPrice,
        mpgRange,
    } = query;

    let nonUniqueCanonical = true;

    if (listingType && !Array.isArray(listingType) && (listingType === 'NEW' || listingType === 'CERTIFIED')) {
        nonUniqueCanonical = false;
    }

    if (sellerType && !Array.isArray('sellerType') && sellerType[0] === 'p') {
        nonUniqueCanonical = false;
    }

    if ((vehicleStyleCode && !Array.isArray(vehicleStyleCode)) || (driveGroup && !Array.isArray(driveGroup))
        || (engineCode && !Array.isArray(engineCode)) || (fuelTypeGroup && !Array.isArray(fuelTypeGroup))) {
        nonUniqueCanonical = false;
    }

    if ((minPrice && !maxPrice) || (maxPrice && !minPrice)) {
        nonUniqueCanonical = false;
    }

    if (mpgRange) {
        nonUniqueCanonical = false;
    }

    const hasMultipleSelections = (key) => Array.isArray(query[key]) && query[key].length > 1;
    const hasSingleModel = !!modelCode && (!hasMultipleSelections('makeCode') || !hasMultipleSelections('modelCode'));
    const hasSingleMake = !!makeCode && !hasMultipleSelections('makeCode');

    // only make and model are selected and has non unique canonical url
    return (hasSingleModel && hasSingleMake && !startYear && !endYear && !trimCode && nonUniqueCanonical);
};

export default function withConsumerRatings() {
    return async (ctx) => {
        const {
            consumer_ratings: [enableConsumerRatings, { srpRatings: enableSrpRatings }],
        } = ctx.useFeatures([
            'consumer_ratings',
        ]);

        if (enableConsumerRatings && enableSrpRatings) {
            const shouldUpdateRatings = shouldUpdateRatingsLsc(ctx);

            if (shouldUpdateRatings) {
                await updateConsumerRatings(ctx);
            } else {
                await ctx.store.dispatch(consumerRatingsDuck?.creators?.reset());
            }
        }

    };
}
