import _get from 'lodash/get';

export default function getSpecificationKeys(filterValues, peData) {
    const preferredFuel = _get(peData, 'PrefFuelCat', null);
    const recentPreferredFuel = _get(peData, 'RecentPrefFuelCat', null);
    const marketingSegments = _get(peData, 'pe_clusters', []);
    const keys = ['mileage'];
    const selectedFuelTypeGroups = _get(filterValues, 'fuelTypeGroup', []);

    // if preferredFuel or recentPreferredFuel === Electric, Gasoline Hybrid, or Plug-In Hybrid, show EV data on card
    // if marketingSegment includes EVSHOPPER, show EV data on card
    if (selectedFuelTypeGroups.includes('PIH') || preferredFuel === 'Plug-In Hybrid' || recentPreferredFuel === 'Plug-In Hybrid') {
        keys.push('mpg', 'mileRange');
    } else {
        if (selectedFuelTypeGroups.includes('ELE') || preferredFuel === 'Electric' || recentPreferredFuel === 'Electric' || marketingSegments.includes('EVSHOPPER')) {
            keys.push('mileRange');
        }
        if (selectedFuelTypeGroups.includes('HYB') || preferredFuel === 'Gasoline Hybrid' || recentPreferredFuel === 'Gasoline Hybrid') {
            keys.push('mpg');
        }
    }

    return keys;
}
