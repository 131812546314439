/**
 * The requirement to show preorder data is user selected make & model and (hasConditionNew || hasYearAndNoCondition);
 *
 * @param filtersRequestValues - filtersRequestValues
 */
export default function getPreorderAvailability(filtersRequestValues) {
    const makeCodeList = filtersRequestValues?.makeCodeList || filtersRequestValues?.makeCode;
    const modelCodeList = filtersRequestValues?.modelCodeList || filtersRequestValues?.modelCode;
    const listingTypes = filtersRequestValues?.listingType || filtersRequestValues?.listingTypes || [];

    const hasMakeAndModel = !!(makeCodeList?.length && modelCodeList?.length);
    const hasConditionNew = !!listingTypes.includes('NEW');
    const hasConditionUsed = !!listingTypes.includes('USED') || !!listingTypes.includes('CERTIFIED')
        || !!listingTypes.includes('3P_CERT');

    return hasMakeAndModel && (hasConditionNew || !hasConditionUsed);
}

/**
 * determine if user is searching for one specific year and if it is in the valid range of one less than current year
 * @param filtersValues
 * @returns {*|boolean}
 */
export function isSingleYear(filtersValues) {
    const startYear = filtersValues?.startYear;
    const endYear = filtersValues?.endYear;
    return startYear && endYear && startYear === endYear && Number(startYear) >= new Date().getFullYear() - 1;
}
