import React, { useCallback, useState } from 'react';

import {
    Alert,
    Button,
    Glyphicon,
} from 'reaxl';
import {
    useAnalytics,
} from 'reaxl-analytics';

function FeedbackContainer({ data }) {
    const { sendClick } = useAnalytics();
    const [showFeedback, isShowFeedback] = useState(false);

    const handleInformationHelpful = useCallback((event) => {
        isShowFeedback(true);
        const feedbackProp = data + event.currentTarget.value;
        sendClick('informationHelpful', event, {
            label: feedbackProp,
        });
    }, [data, sendClick]);

    if (showFeedback) {
        return (
            <Alert
                bsStyle="success"
                className="margin-xs-3"
                style={{ display: 'inline-block', marginBottom: '0px' }}
            >
                <Glyphicon glyph="check" />
                Thank you for your feedback.
            </Alert>
        );
    }

    return (
        <>
            How helpful was this information?

            <Button
                type="submit"
                bsStyle="link"
                aria-label="Helpful"
                onClick={handleInformationHelpful}
                value="yes"
                className="text-size-500 padding-horizontal-2 margin-left-2"
            >
                <Glyphicon
                    glyph="happy-face"
                />
            </Button>
            <Button
                type="submit"
                bsStyle="link"
                aria-label="Unhelpful"
                onClick={handleInformationHelpful}
                value="no"
                className="text-size-500 padding-horizontal-2 margin-right-lg-2"
            >
                <Glyphicon
                    glyph="sad-face"
                />
            </Button>
        </>
    );
}
export default FeedbackContainer;
