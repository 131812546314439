import _get from 'lodash/get';

import { recentSearchDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';

export default function withSavedRecentSearch() {
    return (ctx) => {
        // save this as the most recent search
        const state = ctx.store.getState();

        const locationHeading = _get(ctx, 'data.pageData.locationHeading', '');

        ctx.store.dispatch(recentSearchDuck.creators.saveRecentSearch({
            requestValues: srpFiltersDuck.selectors.getRequestValues(state),
            labelsByValues: srpFiltersDuck.selectors.getLabelsByValues(state),
            requestQueryValues: srpFiltersDuck.selectors.getRequestQueryValues(state),
            locationHeading,
            // remove the search string before passing to the save action.
            // TODO: BONNET - This parameter should be removed completely
            // and recent searches should be built by bonnet-paths
            canonicalPathname: ctx.data.currentUrl.slice(0, ctx.data.currentUrl.indexOf('?')),
            additionalFilters: {
                sortBy: srpFiltersDuck.selectors.getLocalState(state).sort,
            },
        }));
    };
}
