import { useRouter } from 'next/router';

import { getPath, pageNames } from '@atc/bonnet-paths';

const { BUILD_PRICE } = pageNames;

export default function useBapNavigation() {
    const router = useRouter();

    /**
     * * This function handles the navigation of the srp and vdp to BAP page and also fires a page load in analytics
     *
     * @param listingId
     * @param makeCode - required
     * @param modelCode - required
     * @param ownerId - use to fetch initial owner data
     * @param trim
     * @param year
     * @param zip - use to fetch all owner data
     * @returns {Promise<void>}
     */
    const navigateToBap = async ({
        makeCode,
        modelCode,
        ownerId,
        trim = '',
        useReferrer = true,
        year = '',
        zip,
    } = {}) => {

        const bapUrl = await getPath(BUILD_PRICE, {
            makeCode,
            modelCode,
            ownerId,
            trim,
            year,
            zip,
        }, {
            referrer: useReferrer,
            // nextjs router push appends basePath
            // as the result of this call is an imperative router push
            // we shall remove the basePath
            basePath: false,
        });

        router.push(bapUrl);

    };

    return navigateToBap;
}
