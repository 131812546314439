module.exports = [
    {
        lnxCode: 'ATMT995',
        channel: 'HYUNDAI_C360',
    },
    {
        lnxCode: 'ATMT996',
        channel: 'HYUNDAI_C360',
    },
    {
        lnxCode: 'ATMT997',
        channel: 'HYUNDAI_C360',
    },
    {
        lnxCode: 'ATMT998',
        channel: 'HYUNDAI_C360',
    },
    {
        lnxCode: 'ATMT999',
        channel: 'HYUNDAI_C360',
    },
    {
        lnxCode: 'ATMT1000',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1033',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1034',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1035',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1036',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1037',
        channel: 'HYUNDAI_C360',
    },
    {
        psidCode: 'MT1038',
        channel: 'HYUNDAI_C360',
    },
];
